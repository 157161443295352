import React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Validations from "../../../../components/Utility/Validations";
class ExtendSubscriptionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extend_subscription_form: {
                company_id: {
                    label: "Company Id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                subscription_id: {
                    label: "Subscription Id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                subscription_billing_id: {
                    label: "Subscription Billing Id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                end_date: {
                    label: "End Date",
                    type: "datepicker",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
            },
        };
    }

    componentDidMount() {
        let selected_company = this.props?.selected_company;
        const update_extend_subscription_form = { ...this.state.extend_subscription_form };

        update_extend_subscription_form.company_id.value = selected_company?.company_id;
        update_extend_subscription_form.subscription_id.value = selected_company?.subscription?.subscription_id;
        update_extend_subscription_form.subscription_billing_id.value = selected_company?.subscription?.subscription_billing?.subscription_billing_id;
        update_extend_subscription_form.end_date.value = selected_company?.subscription?.subscription_billing?.end_date;

        this.setState({ extend_subscription_form: update_extend_subscription_form });
    }

    inputChangeHandler(event, identifier) {
        const update_extend_subscription_form = { ...this.state.extend_subscription_form };
        const form_element = { ...update_extend_subscription_form[identifier] };

        if (form_element.type === "datepicker") {
            form_element.value = moment(event).format("YYYY-MM-DD");
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_extend_subscription_form[identifier] = form_element;

        this.setState({ extend_subscription_form: update_extend_subscription_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_extend_subscription_form = this.state.extend_subscription_form;
        for (let key in update_extend_subscription_form) {
            let form_element = update_extend_subscription_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_extend_subscription_form[key] = form_element;
        }
        this.setState({ extend_subscription_form: update_extend_subscription_form });
        if (is_form_valid) {
            const form_data = new FormData();
            for (let key in this.state.extend_subscription_form) {
                form_data.append(key, this.state.extend_subscription_form[key].value);
            }
            this.props.UpdateExtendSubscription(form_data);
        }
    };

    render() {
        const { extend_subscription_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="extend_subscription_modal bid-custom-modal "
                    show={this.props.extend_subscription_modal}
                    onHide={() => {
                        this.props.extend_subscription_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Extend Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row ">
                                <div className="col-lg-12 fv-row ">
                                    <DatePicker
                                        peekNextMonth
                                        showYearDropdown
                                        showMonthDropdown
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        dropdownMode="select"
                                        placeholderText="Select section due date"
                                        className="form-control date-picker-outer"
                                        selected={extend_subscription_form.end_date.value ? new Date(extend_subscription_form.end_date.value) : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "end_date")}
                                    />
                                </div>
                                <div className="modal-footer modal_footer_remove_border">
                                    <button type="submit" className="btn btn-primary rounded-50 w-150 hvr-grow">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default ExtendSubscriptionModal;