import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Link, Redirect } from "react-router-dom";

import BillingOverviewModal from "./Modal/BillingOverviewModal";
import BillingAddAdditionalUsersModal from "./Modal/BillingAddAdditionalUsersModal";
import BillingUpgradeSuccessfulModal from "./Modal/BillingUpgradeSuccessfulModal";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
class BillingSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_url: "",
            isAuthenticated: false,
            canUpgradeTo: [],
            payment_information: [],
            subscription_monthly: [],
            subscription_annually: [],
            subscription_packages: [],

            extrasubsciption_users: 0,
            subscription_plantab: "month_tab",
            subscription_package_selected: "",

            billingoverview_modal: false,
            billingaddadditionalusers_modal: false,
            billingupgradesuccessful_modal: false,
            current_subscription: "",
            company_info: {}
        };
    }

    async componentDidMount() {
        await this.GetProducts();
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        if (user_info?.subscription_details?.length > 1) {
            await this.GetPaymentInformation();
        }
        let current_subscription = user_info?.subscription
        if (current_subscription && current_subscription.is_freetrail && user_info?.subscription_details[1]) {
            current_subscription = user_info?.subscription_details[1]
        }
        this.setState({
            company_info: user_info.company,
            current_subscription: current_subscription
        })

        if (current_subscription && current_subscription.subscription_package) {
            if (current_subscription.subscription_package?.package_name && current_subscription.subscription_package?.package_name.toLowerCase && current_subscription.subscription_package?.package_name.toLowerCase() == "individual") {
                let canUpgradeTo = [
                    "team-month", "team-year"
                ]
                if (current_subscription.billing_cycle === "month") {
                    canUpgradeTo.push("individual-year")
                }
                this.setState({
                    canUpgradeTo: canUpgradeTo
                })
            } else if (current_subscription.subscription_package?.package_name && current_subscription.subscription_package?.package_name.toLowerCase && current_subscription.subscription_package?.package_name.toLowerCase() == "team") {
                let canUpgradeTo = [
                ]
                if (current_subscription.billing_cycle === "month") {
                    canUpgradeTo.push("team-year")
                }
                this.setState({
                    canUpgradeTo: canUpgradeTo
                })
            } else {
                this.setState({
                    canUpgradeTo: [
                        "team-month", "team-year", "individual-month", "individual-year"
                    ]
                })
            }
        } else {
            this.setState({
                canUpgradeTo: [
                    "team-month", "team-year", "individual-month", "individual-year"
                ]
            })
        }
        // await this.GetSubscriptionPackages();
    }

    GetPaymentInformation = async () => {
        this.props.onPaymentInformation().then((response) => {
            if (response.success === true) {
                this.setState({ payment_information: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetProducts = async () => {
        this.props.onGetProducts().then((response) => {
            if (response.success === true) {
                let subscriptions = [];
                let subscription_monthly = [];
                let subscription_annually = [];
                let product_response = response.data;

                if (product_response.length > 0) {
                    for (let key in product_response) {
                        let product_detail = product_response[key];
                        if (typeof subscriptions[product_detail.product_id] === "undefined") {
                            subscriptions[product_detail.product_id] = [];
                        }
                        if (typeof subscriptions[product_detail.product_id]["product_detail"] === "undefined") {
                            subscriptions[product_detail.product_id]["product_detail"] = product_detail;
                        }
                        if (product_detail.subscription_packages) {
                            for (let package_key in product_detail.subscription_packages) {
                                let subscription_packages = product_detail.subscription_packages[package_key];
                                if (typeof subscriptions[product_detail.product_id][subscription_packages.billing_cycle] === "undefined") {
                                    subscriptions[product_detail.product_id][subscription_packages.billing_cycle] = subscription_packages;
                                }
                                if (subscription_packages.billing_cycle === "month") {
                                    subscription_monthly.push(subscription_packages);
                                } else {
                                    subscription_annually.push(subscription_packages);
                                }
                            }
                        }
                    }
                    this.setState({
                        subscription_packages: subscriptions,
                        subscription_monthly: subscription_monthly,
                        subscription_annually: subscription_annually,
                    });
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetSubscriptionPackages = async () => {
        this.props.onGetSubscriptionPackages().then((response) => {
            if (response.success === true) {
                let subscriptions = [];
                let subscription_monthly = [];
                let subscription_annually = [];
                let subscription_packages = response.data;
                for (let key in subscription_packages) {
                    let subscription_package = subscription_packages[key];
                    if (typeof subscriptions[subscription_package.product_id] === "undefined") {
                        subscriptions[subscription_package.product_id] = [];
                    }

                    if (typeof subscriptions[subscription_package.product_id][subscription_package.billing_cycle] === "undefined") {
                        subscriptions[subscription_package.product_id][subscription_package.billing_cycle] = subscription_package;
                    }

                    if (subscription_package.billing_cycle === "month") {
                        subscription_monthly.push(subscription_package);
                    } else {
                        subscription_annually.push(subscription_package);
                    }
                }
                this.setState({
                    subscription_packages: subscriptions,
                    subscription_monthly: subscription_monthly,
                    subscription_annually: subscription_annually,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    CreateSubscription = (form_data) => {
        let expiry_date = form_data.expiry_date.split("/");

        const formdata = {};

        formdata["cvc"] = form_data.cvc;
        formdata["exp_year"] = expiry_date[1];
        formdata["exp_month"] = expiry_date[0];
        formdata["cardnumber"] = form_data.cardnumber;
        formdata["card_holdername"] = form_data.card_holdername;

        formdata["user_limits"] = form_data.user_limits;
        formdata["subscription_price"] = form_data.subscription_price;
        formdata["package_feature_id"] = form_data.package_feature_id;
        formdata["extrasubsciption_users"] = form_data.extrasubsciption_users;
        formdata["subscription_discount_id"] = form_data.subscription_discount_id;
        formdata["subscription_package_id"] = form_data.subscription_package_id;

        this.props.onCreateSubscription(formdata).then((response) => {
            if (response.success === true) {
                this.setState({
                    billingoverview_modal: false,
                    billingupgradesuccessful_modal: true,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        const { current_subscription, canUpgradeTo, subscription_plantab, subscription_monthly, subscription_annually, subscription_packages, subscription_package_selected } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.billingoverview_modal && (
                    <BillingOverviewModal
                        ref={(ref) => (this.BillingOverviewEvent = ref)}
                        billingoverview_modal={this.state.billingoverview_modal}
                        payment_information={this.state.payment_information}
                        subscription_packages={subscription_packages}
                        extrasubsciption_users={this.state.extrasubsciption_users}
                        subscription_package_selected={subscription_package_selected}
                        company_info={this.state.company_info}
                        CreateSubscription={(form_data) => this.CreateSubscription(form_data)}
                        CompanyUpdate={(form_data) => this.props.onCompanyUpdate(form_data)}
                        ChangeSubscriptionPackage={(product_id) => {
                            if (subscription_packages[product_id]) {
                                let billing_cycle = subscription_package_selected.billing_cycle;

                                if (subscription_packages[product_id][billing_cycle] !== "undefined" && billing_cycle === "month") {
                                    billing_cycle = "year";
                                }

                                if (subscription_packages[product_id][billing_cycle] !== "undefined" && billing_cycle === "year") {
                                    billing_cycle = "month";
                                }

                                this.setState(
                                    {
                                        extrasubsciption_users: 0,
                                        subscription_package_selected: subscription_packages[product_id][billing_cycle],
                                    },
                                    function () {
                                        if (this.BillingOverviewEvent && this.BillingOverviewEvent !== undefined) {
                                            this.BillingOverviewEvent.BillingOverviewReload();
                                        }
                                    }
                                );
                            }
                        }}
                        billingcyclechange_action={(new_plan) => {
                            this.setState(
                                {
                                    extrasubsciption_users: 0,
                                    subscription_package_selected: new_plan,
                                },
                                function () {
                                    if (this.BillingOverviewEvent && this.BillingOverviewEvent !== undefined) {
                                        this.BillingOverviewEvent.BillingOverviewReload();
                                    }
                                }
                            );
                        }}
                        billingaddadditionalusers_action={(event) => {
                            this.setState({
                                billingaddadditionalusers_modal: true,
                            });
                        }}
                        billingoverview_modal_action={(modal_action) => {
                            this.setState({
                                billingoverview_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.billingaddadditionalusers_modal && (
                    <BillingAddAdditionalUsersModal
                        subscription_package_selected={subscription_package_selected}
                        billingaddadditionalusers_modal={this.state.billingaddadditionalusers_modal}
                        extrasubsciption_users={this.state.extrasubsciption_users}
                        BillingAddAdditionalUsersSubmit={(extrasubsciption_users) => {
                            this.setState(
                                {
                                    billingaddadditionalusers_modal: false,
                                    extrasubsciption_users: extrasubsciption_users,
                                },
                                function () {
                                    if (this.BillingOverviewEvent && this.BillingOverviewEvent !== undefined) {
                                        this.BillingOverviewEvent.BillingOverviewReload();
                                    }
                                }
                            );
                        }}
                        billingaddadditionalusers_modal_action={(modal_action) => {
                            this.setState({
                                billingaddadditionalusers_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.billingupgradesuccessful_modal && (
                    <BillingUpgradeSuccessfulModal
                        billingupgradesuccessful_modal={this.state.billingupgradesuccessful_modal}
                        billingupgradesuccessful_modal_action={(modal_action) => {
                            this.setState(
                                {
                                    billingupgradesuccessful_modal: modal_action,
                                },
                                function () {
                                    window.location.href = "/settings-billing";
                                    // this.setState({
                                    // isAuthenticated: true,
                                    // redirect_url: "/settings-billing",
                                    // });
                                }
                            );
                        }}
                    />
                )}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-myprofile" className="nav-link text-active-primary me-6">
                                                        My profile
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-company" className="nav-link text-active-primary me-6">
                                                            Company details
                                                        </Link>
                                                    </li>
                                                )}
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6">
                                                        Account
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-billing" className="nav-link text-active-primary me-6 active">
                                                            Billing
                                                        </Link>
                                                    </li>
                                                )}
                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-users" className="nav-link text-active-primary me-6">
                                                            Users
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10 mx-auto">
                                                <div className="card mb-5 mb-xl-6 bg-transparent">
                                                    <div className="card-header border-0 p-0 mb-5 align-items-center pricing-plans">
                                                        <h3 className="card-title align-items-start flex-column mb-4">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-3">Plans and pricing</span>
                                                            <span className="text-dark fs-6 fw-bold ">Take the first step towards writing better bids and winning more business, more often.</span>
                                                        </h3>

                                                        <div className="nav-group choose-plan-block d-flex mb-4">
                                                            <span
                                                                className={"btn btn-active active-light-btn-info rounded-50 fw-bold-700 me-2" + (subscription_plantab === "month_tab" ? " active" : "")}
                                                                onClick={(event) => {
                                                                    this.setState({
                                                                        subscription_plantab: "month_tab",
                                                                    });
                                                                }}
                                                            >
                                                                Billed Monthly
                                                            </span>
                                                            <span
                                                                className={"btn btn-active active-light-btn-info rounded-50 fw-bold-700" + (subscription_plantab === "annually_tab" ? " active" : "")}
                                                                onClick={(event) => {
                                                                    this.setState({
                                                                        subscription_plantab: "annually_tab",
                                                                    });
                                                                }}
                                                            >
                                                                Billed Annually <small className="fw-bold">10% off!</small>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-0 pb-5 plans-card">
                                                        <div className="row">
                                                            {subscription_plantab === "month_tab"
                                                                ? subscription_monthly.length !== 0 &&
                                                                subscription_monthly.map((monthly) => (
                                                                    <div className={"col-md-6 month_tab package_name_" + monthly.package_name.toLowerCase()} key={"subscription_" + monthly.subscription_package_id}>
                                                                        <div className="card p-4 plan-one">
                                                                            <div className="card-header justify-content-center text-center p-2 border-bottom-2">
                                                                                <div className="d-flex mb-4 align-items-center">
                                                                                    <div className="symbol-100px symbol-fixed position-relative me-3 ">
                                                                                        <i className="icon-subscription fs-3"></i>
                                                                                    </div>
                                                                                    <h4 className="m-0 fw-bolder">{monthly.package_name}</h4>
                                                                                </div>
                                                                                <p className="mb-8">{renderHTML(monthly?.package_description)}</p>
                                                                                {/* <div className="beta-launch-off">
                                                                                    <span>BETA Launch 50% off</span>
                                                                                </div> */}
                                                                                <div className="mb-8  w-100">
                                                                                    <div className="price-range-new d-flex justify-content-center">
                                                                                        {/* <h2 className="fw-bold-600 fs-4x m-0 old-price">
                                                                                            <sup>$</sup>
                                                                                            {monthly.price}
                                                                                        </h2> */}
                                                                                        <h2 className="fw-bold-600 fs-4x m-0 new-price">
                                                                                            <sup>$</sup>
                                                                                            {(parseInt(monthly.price) / 100) * parseInt(50)}
                                                                                        </h2>
                                                                                    </div>
                                                                                    <h5 className="fw-bold-700 m-0">AUD per month</h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-body p-2">
                                                                                {renderHTML(monthly?.package_features)}
                                                                                {canUpgradeTo.indexOf(`${monthly.package_name.toLowerCase()}-month`) != -1 ?
                                                                                    (<span
                                                                                        className="btn btn-outline  plan-btn  w-100 rounded-50 btn-active-info"
                                                                                        onClick={(event) => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    subscription_package_selected: monthly,
                                                                                                },
                                                                                                function () {
                                                                                                    this.setState({
                                                                                                        billingoverview_modal: true,
                                                                                                    });
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Upgrade to {monthly.package_name} Monthly
                                                                                    </span>) :
                                                                                    (<span
                                                                                        className="btn btn-outline plan-btn w-100 rounded-50 btn-active-info"
                                                                                    >
                                                                                        {(current_subscription?.subscription_package?.package_name == monthly.package_name && current_subscription?.billing_cycle == 'month') ? 'Your current plan' : monthly.package_name}
                                                                                    </span>)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                : subscription_annually.length !== 0 &&
                                                                subscription_annually.map((annually) => (
                                                                    <div className={"col-md-6 annually_tab package_name_" + annually.package_name.toLowerCase()} key={"subscription_" + annually.subscription_package_id}>
                                                                        <div className="card p-4 plan-one">
                                                                            <div className="card-header justify-content-center text-center p-2 border-bottom-2">
                                                                                <div className="d-flex mb-4 align-items-center">
                                                                                    <div className="symbol-100px symbol-fixed position-relative me-3 ">
                                                                                        <i className="icon-subscription fs-3"></i>
                                                                                    </div>
                                                                                    <h4 className="m-0 fw-bolder">{annually.package_name}</h4>
                                                                                </div>
                                                                                <p className="mb-8">{renderHTML(annually?.package_description)}</p>
                                                                                {/* <div className="beta-launch-off">
                                                                                    <span>BETA Launch 50% off</span>
                                                                                </div> */}
                                                                                <div className="mb-8  w-100">
                                                                                    <div className="price-range-new d-flex justify-content-center">
                                                                                        {/* <h2 className="fw-bold-600 fs-4x m-0 old-price">
                                                                                            <sup>$</sup>
                                                                                            {annually.price}
                                                                                        </h2> */}
                                                                                        <h2 className="fw-bold-600 fs-4x m-0 new-price">
                                                                                            <sup>$</sup>
                                                                                            {(parseInt(annually.price) / 100) * parseInt(50)}
                                                                                        </h2>
                                                                                    </div>
                                                                                    <h5 className="fw-bold-700 m-0">AUD per year</h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-body p-2">
                                                                                {renderHTML(annually?.package_features)}
                                                                                {canUpgradeTo.indexOf(`${annually.package_name.toLowerCase()}-year`) != -1 ?
                                                                                    (<span
                                                                                        className="btn btn-outline plan-btn w-100 rounded-50 btn-active-info"
                                                                                        onClick={(event) => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    subscription_package_selected: annually,
                                                                                                },
                                                                                                function () {
                                                                                                    this.setState({
                                                                                                        billingoverview_modal: true,
                                                                                                    });
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Upgrade to {annually.package_name} Annually
                                                                                    </span>) :
                                                                                    (<span
                                                                                        className="btn btn-outline plan-btn w-100 rounded-50 btn-active-info"
                                                                                    >
                                                                                        {(current_subscription?.subscription_package?.package_name == annually.package_name && current_subscription?.billing_cycle != 'month') ? 'Your current plan' : annually.package_name}
                                                                                    </span>)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card mb-5 mb-xl-6 bg-transparent">
                                                    <h4 className="fw-bolder fs-2x mb-5 mt-0">Frequently Asked Questions</h4>
                                                    <div className="accordion bg-transparent custom-accordion-style" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    What’s the difference between the Individual and Team plan?
                                                                </button>
                                                            </h2>
                                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    For both Individual and Team, all of the features of BidHQ are available to you. However, the Team plan is designed for teams. You will have 5 user licenses so you can share and
                                                                    collaborate.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingTwo">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                    Can I add extra licenses?
                                                                </button>
                                                            </h2>
                                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">Absolutely! Additional user licenses, or 'seats', can be added for an extra $55 per person per month.</div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                    How do I know which plan is right for me?
                                                                </button>
                                                            </h2>
                                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    If you're an individual, the Individual plan is right for you. It will give you access to all of BidHQ's features, guidance and support. If you're a team, the right choice is
                                                                    the Team plan. This package comes with up to 5 licenses for you to communicate and collaborate with your team.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingFour">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                    Should I pay monthly or annually?
                                                                </button>
                                                            </h2>
                                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    A monthly subscription gives you the flexibility to pay month-to-month and cancel at any time. Our most popular subscription is the annual license, where you pay upfront
                                                                    and save $198 per year for the Individual plan and $598 per year for Team!
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-md-flex query-block mt-15 justify-content-center text-sm-center align-items-center ">
                                                        <h4 className="fw-bolder m-0 fs-2x mb-sm-2 mb-md-0">Still have questions?</h4>
                                                        <Link to="https://support.bidhq.com.au/" className="btn btn-sm btn-primary rounded-50 ms-4 px-10 hvr-grow">
                                                            Get in touch
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCompanyUpdate: userActions.CompanyUpdate,
    onGetProducts: userActions.GetProducts,
    onPaymentInformation: userActions.PaymentInformation,
    onGetSubscriptionPackages: userActions.GetSubscriptionPackages,
    onCreateSubscription: userActions.CreateSubscription,
};
export default connect(mapStateToProps, mapDispatchToProps)(BillingSubscription);