import * as Master_actionType from "../constants/MasteractionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case Master_actionType.UpdatePersonalAvatar_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdatePersonalAvatar_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdatePersonalAvatar_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdatePersonal_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdatePersonal_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdatePersonal_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveProfileImage_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveProfileImage_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveProfileImage_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdatePassword_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdatePassword_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdatePassword_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.TwoFactorAccountVerify_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.TwoFactorAccountVerify_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.TwoFactorAccountVerify_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.ResendTwoFactorOTPVerify_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.ResendTwoFactorOTPVerify_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.ResendTwoFactorOTPVerify_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.EnableTwoFactorOTPVerify_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.EnableTwoFactorOTPVerify_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.EnableTwoFactorOTPVerify_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.DisableTwoFactorAccountVerify_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DisableTwoFactorAccountVerify_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DisableTwoFactorAccountVerify_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateUserNotification_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateUserNotification_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateUserNotification_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.CompanyUpdateAvatar_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CompanyUpdateAvatar_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CompanyUpdateAvatar_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.CompanyUpdate_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CompanyUpdate_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CompanyUpdate_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveCompanyLogo_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveCompanyLogo_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveCompanyLogo_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetCompanySubscription_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCompanySubscription_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCompanySubscription_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetAllUsers_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetAllUsers_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetAllUsers_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateUser_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateUser_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateUser_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveUser_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveUser_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveUser_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetInviteUsers_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetInviteUsers_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetInviteUsers_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.InviteUser_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.InviteUser_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.InviteUser_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateInviteUser_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateInviteUser_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateInviteUser_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.ReInviteUser_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.ReInviteUser_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.ReInviteUser_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveInviteUser_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveInviteUser_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveInviteUser_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetCompanyUsers_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCompanyUsers_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCompanyUsers_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetCompanyReassignUsers_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCompanyReassignUsers_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCompanyReassignUsers_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetProducts_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetProducts_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetProducts_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetCurrentSubscription_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCurrentSubscription_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCurrentSubscription_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetSubscriptionPackages_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetSubscriptionPackages_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetSubscriptionPackages_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.CreateSubscription_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CreateSubscription_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CreateSubscription_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.AddCard_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.AddCard_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.AddCard_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.DeleteCard_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DeleteCard_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DeleteCard_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.MakecardPrimary_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.MakecardPrimary_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.MakecardPrimary_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.PaymentInformation_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.PaymentInformation_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.PaymentInformation_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.PaymentHistory_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.PaymentHistory_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.PaymentHistory_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BillingContactSupport_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BillingContactSupport_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BillingContactSupport_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.VerifyDiscount_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.VerifyDiscount_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.VerifyDiscount_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        default:
            return state;
    }
};

export default reducer;