import React, { useState, useEffect, useCallback, useRef } from "react";

import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";
import { render } from "@testing-library/react";

import CompanyItems from "./CompanyItems";
import ExtendSubscriptionModal from "./Modal/ExtendSubscriptionModal";

import ConfirmDialog from "../../../components/Utility/ConfirmDialog";

import Loader from "../../../components/Utility/Loader";
import LoginSidebar from "../../../components/SuperAdminSidebar";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginFooter from "../../../components/SuperAdminFooter";
import * as SuperAdminUserActions from "../../../actions/SuperAdmin/index";

const CompanyLists = (props) => {
    const dispatch = useDispatch();
    const ConfirmDialogEvent = useRef(null);

    const [loader, setloader] = useState(false);
    const [companies, setcompanies] = useState([]);
    const [companycount, setcompanycount] = useState(0);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("company_id");
    const [filterorder, setfilterorder] = useState("desc");
    const [filtersearch, setfiltersearch] = useState(null);

    const [selected_company, setselected_company] = useState(true);
    const [extend_subscription_modal, setextend_subscription_modal] = useState(false);

    const GetAllCompanies = useCallback(() => {
        dispatch(SuperAdminUserActions.GetAllCompanies(filtersearch, filterpage, filterlimit, filtersort, filterorder)).then((response) => {
            if (response.success === true) {
                setcompanies(response.data);
                setcompanycount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    useEffect(() => {
        GetAllCompanies();
    }, [GetAllCompanies, filterlimit, filterpage, filtersort, filterorder, filtersearch]);

    const GetAllCompaniesExcel = () => {
        dispatch(
            SuperAdminUserActions.GetAllCompaniesExcel({
                search: filtersearch,
            })
        ).then((response) => {
            if (response.success === true) {
                const link = document.createElement("a");
                link.href = response.data;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const ConfirmChangeCompanyStatus = (event, company_detail, company_status) => {
        render(
            <ConfirmDialog
                title="Company Status Change ?"
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                body="Are you sure you want to change user status?"
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                onConfirm={(event) => ChangeCompanyStatus(company_detail, company_status)}
            />
        );
    };

    const ChangeCompanyStatus = (company_detail, company_status) => {
        const form_data = new FormData();
        form_data.append("company_uud", company_detail.company_uud);
        form_data.append("company_status", company_status);

        setloader(true);
        dispatch(SuperAdminUserActions.ChangeCompanyStatus(form_data)).then((response) => {
            setloader(false);
            if (response.success === true) {
                GetAllCompanies();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const ConfirmDeleteCompany = (company_detail) => {
        render(
            <ConfirmDialog
                title="Delete Company?"
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                body="Are you sure you want to delete this company?"
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                onConfirm={(event) => DeleteCompany(company_detail)}
            />
        );
    };

    const DeleteCompany = (company_detail) => {
        dispatch(SuperAdminUserActions.DeleteCompany(company_detail.company_uud)).then((response) => {
            if (response.success === true) {
                setfilterpage(1);
                // GetAllCompanies();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const ExtendSubscription = (company_detail) => {
        setextend_subscription_modal(true);
        setselected_company(company_detail);
    };

    const UpdateExtendSubscription = (form_data) => {
        dispatch(SuperAdminUserActions.UpdateExtendSubscription(form_data)).then((response) => {
            if (response.success === true) {
                GetAllCompanies();
                setselected_company([]);
                setextend_subscription_modal(false);
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            {props.loader ? <Loader /> : null}

            {extend_subscription_modal && (
                <ExtendSubscriptionModal
                    selected_company={selected_company}
                    extend_subscription_modal={extend_subscription_modal}
                    UpdateExtendSubscription={(form_data) => UpdateExtendSubscription(form_data)}
                    extend_subscription_modal_action={(modal_action) => {
                        setextend_subscription_modal(modal_action);
                    }}
                />
            )}

            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="Users" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-4 mb-xl-8 bg-transparent">
                                                <div className="card-header border-0 p-0 align-items-center">
                                                    <h3 className="card-title align-items-start flex-column m-0">
                                                        <span className="card-label fw-bolder fs-22px mb-2">Companies</span>
                                                    </h3>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>

                                    <CompanyItems
                                        companies={companies}
                                        companycount={companycount}
                                        filtersort={filtersort}
                                        filterlimit={filterlimit}
                                        filterpage={filterpage}
                                        filterorder={filterorder}
                                        propsfiltersort={(field) => setfiltersort(field)}
                                        propsfilterorder={(order) => setfilterorder(order)}
                                        propsfilterpage={(event) => setfilterpage(event)}
                                        propsfiltersearch={(event) => setfiltersearch(event)}
                                        GetAllCompaniesExcel={() => GetAllCompaniesExcel()}
                                        ExtendSubscription={(company_detail) => ExtendSubscription(company_detail)}
                                        ConfirmDeleteCompany={(company_detail) => ConfirmDeleteCompany(company_detail)}
                                        ConfirmChangeCompanyStatus={(event, company_detail, company_status) => ConfirmChangeCompanyStatus(event, company_detail, company_status)}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

function mapStateToProps(state) {
    return {
        error: state.superdmin_users.error,
        loader: state.superdmin_users.loader,
        isAuthenticated: state.superdmin_users.isAuthenticated,
    };
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyLists);