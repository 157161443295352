import React from "react";

import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import ReactPlayer from "react-player";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as userActions from "../../../../actions/index";
import ButtonDropzone from "../../../../components/Utility/ButtonDropzone/ButtonDropzone";
class BidRightSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_detail: [],
            tender_media: [],
            bidactivitylogs: [],
            bidactivitylog_limit: 3,

            latest_blogs: [],

            library_conents: [],
            library_proposals: [],

            bid_response_form: "",
            bid_section_selected: "",

            section_duedate_editbale: false,
            bidcreate_rightsidetab_activetab: "global",
            bidcreate_global_activetab: "global_team",
            bidcreate_section_activetab: "section_team",
        };
    }

    componentDidMount() {
        let tender_media = []
        if (this.props.bid_detail.response_media && this.props.bid_detail.response_media.length)
            for (const item of this.props.bid_detail.response_media) {
                if (item.is_tender) {
                    tender_media.push(item)
                }
            }
        this.setState({
            library_conents: [],
            bid_detail: this.props.bid_detail,
            tender_media: tender_media,
            bidcreate_rightsidetab_activetab: "global",
            bid_response_form: this.props.bid_response_form,
            bid_section_selected: this.props.bid_section_selected,
        });

        if (this.props.bid_section_selected) {
            this.setState({
                bidcreate_rightsidetab_activetab: "section",
            });
        }
        this.GetLibraryWithContents();
        this.GetLibraryPastProposals();
        this.GetBidActivityLogs();
        this.BidGlobalTips();
    }

    BidRightSideBarReload() {
        let tender_media = []
        if (this.props.bid_detail.response_media && this.props.bid_detail.response_media.length)
            for (const item of this.props.bid_detail.response_media) {
                if (item.is_tender) {
                    tender_media.push(item)
                }
            }
        this.setState({
            bid_detail: this.props.bid_detail,
            tender_media: tender_media,
            section_duedate_editbale: false,
            bidcreate_rightsidetab_activetab: "global",
            bid_response_form: this.props.bid_response_form,
            bid_section_selected: this.props.bid_section_selected,
        });

        if (this.props.bid_section_selected) {
            this.setState({
                bidcreate_rightsidetab_activetab: "section",
            });
        }

        this.GetBidActivityLogs();
    }

    BidGlobalTips() {
        this.props.onGetLatestBidHQ().then((response) => {
            if (response.success === true) {
                this.setState({ latest_blogs: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetLibraryWithContents(search) {
        this.props.onGetLibraryWithContents(search).then((response) => {
            if (response.success === true) {
                this.setState({
                    library_conents: response.data,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetLibraryPastProposals(search) {
        this.props.onGetLibraryPastProposals(search).then((response) => {
            if (response.success === true) {
                this.setState({
                    library_proposals: response.data,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    AddNewBidproposal(event, proposal) {
        event.preventDefault();
        this.props.AddNewBidproposal(event, proposal);
    }

    GetBidActivityLogs = () => {
        this.props.onGetBidActivityLogs(this.props.bid_detail.bid_id, this.state.bidactivitylog_limit).then((response) => {
            if (response.success === true) {
                this.setState({
                    bidactivitylogs: response.data,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    downloadDocument = (item) => {
        const link = document.createElement("a");
        link.href = item.avatar;
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    filterMedia = (value) => {
        let tender_media = []
        if (this.props.bid_detail.response_media && this.props.bid_detail.response_media.length)
            for (const item of this.props.bid_detail.response_media) {
                if (item.is_tender) {
                    if (value.length && item.avatar_title.length && item.avatar_title.toLowerCase().includes(value.toLowerCase())) {
                        tender_media.push(item)
                    } else if (!value) {
                        tender_media.push(item)
                    }
                }
            }
        this.setState({
            tender_media: tender_media
        })
    }

    checkOwner = (user_info) => {
        if (user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") {
            return true
        }
        let isOwner = false
        if (this.props.bid_detail && this.props.bid_detail.bid_owners && this.props.bid_detail.bid_owners.length) {
            for (const owner of this.props.bid_detail.bid_owners) {
                if (owner.user_id === user_info.user.user_id) {
                    isOwner = true
                }
            }
        }
        return isOwner
    }

    checkGuest = (user_info) => {
        if (user_info.user.role.user_role === "Guest") {
            return true
        }
        return false
    }

    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        const { bid_detail, tender_media, bid_response_form, bidcreate_rightsidetab_activetab, bidcreate_global_activetab, bidcreate_section_activetab, bid_section_selected, library_conents, library_proposals } = this.state;
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="wrap-right-info wrap-right-sidebar">
                    <div className={"card-toolbar m-0" + (bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")}>
                        <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder" role="tablist">
                            <li role="presentation" className="nav-item">
                                <span
                                    className={"nav-link justify-content-center text-gray-400 curser_pointer " + (bidcreate_rightsidetab_activetab === "global" ? "active" : "")}
                                    onClick={(event) => {
                                        this.setState({ bidcreate_rightsidetab_activetab: "global" });
                                        this.props.BidSectionUnselected(event);
                                    }}
                                >
                                    Global
                                </span>
                            </li>
                            <li role="presentation" className="nav-item">
                                <span
                                    className={"nav-link justify-content-center text-gray-400 curser_pointer section-active " + (bidcreate_rightsidetab_activetab === "section" ? "active" : "")}
                                    onClick={(event) => {
                                        if (this.state.bid_section_selected === undefined) {
                                            notify.show("Please select a section first.", "error");
                                        } else {
                                            this.setState({ bidcreate_rightsidetab_activetab: "section" });
                                        }
                                    }}
                                >
                                    Section
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        {bidcreate_rightsidetab_activetab === "global" && (
                            <div className={"card-body p-0 tab-pane fade" + (bidcreate_rightsidetab_activetab === "global" ? " show active" : "")}>
                                <div className={(bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")}>
                                    <ul className="global-tab right-sidebar-icons nav nav-tabs border-bottom-0 inner-nav-tabs nav-line-tabs nav-stretch" role="tablist">
                                        <li className="nav-item curser_pointer">
                                            <span
                                                id="kt_activity_team_tab"
                                                className={"nav-link justify-content-center text-gray-400" + (bidcreate_global_activetab === "global_team" ? " active" : "")}
                                                onClick={(event) => {
                                                    this.setState({ bidcreate_global_activetab: "global_team" });
                                                }}
                                            >
                                                <i className="icon-team team-icon1"></i>Team
                                            </span>
                                        </li>
                                        <li className="nav-item curser_pointer">
                                            <span
                                                id="kt_activity_bidinfo_tab"
                                                className={"nav-link justify-content-center text-active-gray-800" + (bidcreate_global_activetab === "bid_info" ? " active" : "")}
                                                onClick={(event) => {
                                                    this.setState({ bidcreate_global_activetab: "bid_info" });
                                                }}
                                            >
                                                <i className="icon-info1"></i>Bid Info
                                            </span>
                                        </li>
                                        {!this.checkGuest(user_info) && <li className="nav-item curser_pointer">
                                            <span
                                                id="kt_activity_tips_tab"
                                                className={"nav-link justify-content-center text-active-gray-800" + (bidcreate_global_activetab === "tips" ? " active" : "")}
                                                onClick={(event) => {
                                                    this.setState({ bidcreate_global_activetab: "tips" });
                                                }}
                                            >
                                                <i className="icon-file-folder"></i> Bid Docs
                                            </span>
                                        </li>}
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    {bidcreate_global_activetab === "global_team" && (
                                        <div id="kt_activity_team" className="card-body p-0 tab-pane fade active show bid-status-sidebar">
                                            <div className="scroll-y right-scroll-height">
                                                <div className="py-5 px-3 border-bottom">
                                                    <h6 className="text-pink fs-6 mb-3">Bid Status</h6>
                                                    {bid_detail.bid_status && (
                                                        <div className="dropdown">
                                                            <div
                                                                className={
                                                                    "w-110px border progress-text fs-7 p-1 ps-2 rounded-50 d-flex align-items-center curser_pointer title_vector_" + bid_detail.bid_status.replace(" ", "_").toLowerCase()
                                                                }
                                                                id="bid_response_sidebar_bid_status"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <span className="svg-icon-5 ">
                                                                    <i className={"icon_vector_" + bid_detail.bid_status.replace(" ", "_").toLowerCase()}></i>
                                                                </span>
                                                                {bid_detail.bid_status}
                                                            </div>
                                                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                                <div className="dropdown-menu status-response" aria-labelledby="bid_response_sidebar_bid_status">
                                                                    <span className="fs-6 dropdown-item text-main-color bit_status_registered d-none" onClick={(event) => this.props.BidStatusUpdate(event, "Registered")}>
                                                                        Registered
                                                                    </span>
                                                                    <span className="fs-6 dropdown-item bit_status_in_progress" onClick={(event) => this.props.BidStatusUpdate(event, "In progress")}>
                                                                        In progress
                                                                    </span>
                                                                    <span className="fs-6 dropdown-item bit_status_submitted" onClick={(event) => this.props.BidStatusUpdate(event, "Submitted")}>
                                                                        Submitted
                                                                    </span>
                                                                    <span className="fs-6 dropdown-item bit_status_won" onClick={(event) => this.props.BidStatusUpdate(event, "Won")}>
                                                                        Won
                                                                    </span>
                                                                    <span className="fs-6 dropdown-item bit_status_lost" onClick={(event) => this.props.BidStatusUpdate(event, "Lost")}>
                                                                        Lost
                                                                    </span>
                                                                    <span className="fs-6 dropdown-item bit_status_withdrawn" onClick={(event) => this.props.BidStatusUpdate(event, "Withdrawn")}>
                                                                        Withdrawn
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={"py-5 px-3 border-bottom" + (bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")}>
                                                    <h6 className="text-pink fs-6 mb-2">Assignees</h6>
                                                    <span className="fs-7 pb-2 d-block text-gray-400 ">Team ({bid_detail.bid_teams ? bid_detail.bid_teams.length : 0}):</span>
                                                    <div className="symbol-group symbol-hover mb-4">
                                                        {bid_detail.bid_teams &&
                                                            bid_detail.bid_teams.map((bid_team) => {
                                                                return (
                                                                    <div className="custom-tooltip" key={"bid_team_" + bid_team.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {bid_team && bid_team.avatar ? (
                                                                                <span className="symbol_img symbol-label">
                                                                                    <img src={bid_team.avatar} alt={bid_team.first_name} />
                                                                                    <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                                                    {bid_team.first_name?.slice(0, 1) + bid_team.last_name?.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
                                                            <span
                                                                className="symbol-label bg-secondary-dark text-inverse-primary fw-bolder"
                                                                onClick={() => {
                                                                    if (user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") {
                                                                        this.props.bid_team_modal_action(true);
                                                                    } else {
                                                                        notify.show("You does not have this permission", "error");
                                                                    }
                                                                }}
                                                            >
                                                                <img src="assets/images/icons/plus.svg" title="" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span className="fs-7 pb-2 d-block text-gray-400 ">Managers ({bid_detail.bid_owners ? bid_detail.bid_owners.length : 0}):</span>
                                                    <div className="symbol-group symbol-hover mb-4">
                                                        {bid_detail.bid_owners &&
                                                            bid_detail.bid_owners.map((bid_owner) => {
                                                                return (
                                                                    <div className="custom-tooltip" key={"bid_owner_" + bid_owner.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {bid_owner && bid_owner.avatar ? (
                                                                                <span className="symbol_img symbol-label">
                                                                                    <img src={bid_owner.avatar} alt={bid_owner.first_name} />
                                                                                    <span className="custom-tooltip-text">{bid_owner.first_name + " " + bid_owner.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                                                    {bid_owner.first_name?.slice(0, 1) + bid_owner.last_name?.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text">{bid_owner.first_name + " " + bid_owner.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
                                                            <span
                                                                className="symbol-label bg-secondary-dark text-inverse-primary fw-bolder"
                                                                onClick={() => {
                                                                    if (user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") {
                                                                        this.props.bid_owner_modal_action(true);
                                                                    } else {
                                                                        notify.show("You does not have this permission", "error");
                                                                    }
                                                                }}
                                                            >
                                                                <img src="assets/images/icons/plus.svg" title="" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"py-5 px-3" + (bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")}>
                                                    <div className="d-flex mb-3 justify-content-between align-items-center">
                                                        <h6 className="text-pink fs-6 m-0">Comments</h6>
                                                        <span
                                                            className="curser_pointer"
                                                            onClick={() => {
                                                                this.props.comment_sidebar_action(true);
                                                            }}
                                                        >
                                                            <i className="icon-message1 text-pink"></i>
                                                        </span>
                                                    </div>
                                                    {bid_detail.bid_response_comments &&
                                                        bid_detail.bid_response_comments.map((bid_comment, comment_key) => {
                                                            return (
                                                                <div className="d-flex mb-5" key={"comment_" + bid_comment.comment.comment_id}>
                                                                    <div className="symbol symbol-30px me-3">
                                                                        <img title="user" alt="user" src={bid_comment.comment.user.avatar ? bid_comment.comment.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                                    </div>
                                                                    <div className="d-flex flex-column flex-row-fluid">
                                                                        <div className="d-flex align-items-center flex-wrap mb-1">
                                                                            <span className="text-grey-dark text-hover-primary fw-bold-700 me-2 fs-7">
                                                                                {bid_comment.comment.user.first_name} {bid_comment.comment.user.last_name}
                                                                            </span>
                                                                            <span className="text-grey-dark time-date-wrap fs-7">{moment(bid_comment.comment.comment_date).fromNow()}</span>
                                                                        </div>
                                                                        <span className="text-grey-dark fs-7 fw-normal pb-2">{renderHTML(bid_comment.comment.comment_text)}</span>
                                                                        <span
                                                                            className="text-pink fw-bold fs-7 curser_pointer"
                                                                            onClick={(event) => {
                                                                                this.props.BidCommentReply(event, bid_comment);
                                                                            }}
                                                                        >
                                                                            <i className="text-pink icon-Reply me-1"></i> {bid_comment.comment.reply_count} replies
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    {this.state.bidactivitylogs.length > 0 && (
                                                        <div className="pt-0">
                                                            <h6 className="text-pink fs-6 mb-3">Activity</h6>
                                                            <div>
                                                                <div className="mb-2">
                                                                    <ul className="activity-list fs-7 p-0 m-0 text-gray-700">
                                                                        {this.state.bidactivitylogs.map((bidactivitylog) => (
                                                                            <li key={"bidactivitylog_" + bidactivitylog?.system_user_log_id}>
                                                                                <span className="color1 fs-7 notify-txt me-1">{bidactivitylog?.action_user?.first_name + " " + bidactivitylog?.action_user?.last_name}</span>
                                                                                <span className="color1 fs-7 notify-txt notify-txt-2 ">{renderHTML(bidactivitylog?.desktopnotification_html)}</span>
                                                                                <span className="fs-7 d-block text-gray-400 ">{moment(bidactivitylog?.created_date).format("DD/MM/YYYY")}</span>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                    {this.state.bidactivitylog_limit !== "" && (
                                                                        <div
                                                                            className="fs-7 text-gray-700 curser_pointer"
                                                                            onClick={(event) => {
                                                                                this.setState(
                                                                                    {
                                                                                        bidactivitylog_limit: "",
                                                                                    },
                                                                                    function () {
                                                                                        this.GetBidActivityLogs();
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            Show all <span className="icon-right-arrow fs-10 ps-1"></span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {bidcreate_global_activetab === "bid_info" && (
                                        <div
                                            id="kt_activity_bidinfo"
                                            className={
                                                "card-body p-0 tab-pane fade active show" + (bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")
                                            }
                                        >
                                            <div className="scroll-y right-scroll-height right-section-tags">
                                                <div className="py-5 px-3 border-new">
                                                    <h6 className="text-pink fs-6 mb-3">Bid Sections Summary</h6>
                                                    <ol className="bidinfo-list fs-7 p-0 m-0 text-gray-400 fw-bold-600">
                                                        {bid_response_form &&
                                                            bid_response_form.response_content.map((bid_content, bid_content_key) => {
                                                                return <li key={"bid_content_key_" + bid_content_key}>{bid_content.title}</li>;
                                                            })}
                                                    </ol>
                                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                        <div
                                                            className="text-gray-400 fs-7 text-hover-danger curser_pointer fw-bold-600"
                                                            onClick={() => {
                                                                this.props.bid_insert_section_action(true);
                                                            }}
                                                        >
                                                            <span className="d-flex align-items-center insert-icon">
                                                                <i className="fa fa-plus fs-8 me-2 text-gray-400 " aria-hidden="true"></i> Insert a new section
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="py-5 px-3 border-new">
                                                    <h6 className="text-pink fs-6 mb-3">Type of Bid</h6>
                                                    <span className="fs-7 pb-2 d-block text-gray-400 ">{bid_detail.bidtype_meta ? bid_detail.bidtype_meta.title : "-"}</span>
                                                </div>
                                                <div className="py-5 px-3">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="text-pink fs-6 mb-3">Bid Info</h6>
                                                        {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                            <Link to={`/bid-update/${bid_detail.bid_uuid}`}>
                                                                <i className="icon-edit text-pink mb-3 cursor-pointer"></i>
                                                            </Link>
                                                        )}
                                                    </div>

                                                    <div className="mb-2">
                                                        <span className="fs-7 d-block text-gray-400 fw-bold-600 ">Bid name:</span>
                                                        <span className="fs-7 d-block text-gray-400 ">{bid_detail.bid_title}</span>
                                                    </div>
                                                    <div className="mb-2">
                                                        <span className="fs-7 d-block text-gray-400 fw-bold-600 ">Client name:</span>
                                                        <span className="fs-7 d-block text-gray-400 ">{bid_detail.client !== undefined ? bid_detail.client.client_name : "-"}</span>
                                                    </div>
                                                    <div className="mb-0">
                                                        <span className="fs-7 d-block text-gray-400 fw-bold-600 ">Bid reference ID:</span>
                                                        <span className="fs-7 d-block text-gray-400 ">{bid_detail.reference_number}</span>
                                                    </div>
                                                </div>
                                                <div className="py-5 px-3 pt-0">
                                                    <h6 className="text-pink fs-6 mb-3">Date</h6>
                                                    <div className="mb-2">
                                                        <span className="fs-7 d-block text-gray-400 fw-bold-600">Submission due date:</span>
                                                        <span className="fs-7 d-block text-gray-400 ">{moment(bid_detail.submission_date).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                    <div className="mb-0">
                                                        <span className="fs-7 d-block text-gray-400 fw-bold-600">Submission timezone:</span>
                                                        <span className="fs-7 d-block text-gray-400 ">{bid_detail.submission_timezone}</span>
                                                    </div>
                                                </div>
                                                <div className="py-5 px-3 pt-0">
                                                    <h6 className="text-pink fs-6 mb-3">Tags</h6>
                                                    <div className="button-group tags-btn d-flex flex-wrap">
                                                        {bid_detail.bidtag_ids &&
                                                            bid_detail.bidtag_ids.map((bidtag) => (
                                                                <span className="badge badge-primary rounded-50" key={"tag_key_" + bidtag.bidtag_id}>
                                                                    {bidtag ? bidtag.tag_name : "-"}
                                                                </span>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* {bidcreate_global_activetab === "tips" && (
                                        <div
                                            id="kt_activity_tips"
                                            className={
                                                "card-body p-0 tab-pane fade active show" + (bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")
                                            }
                                        >
                                            <div className="scroll-y right-scroll-height">
                                                <div className="py-5 px-3">
                                                    {latest_blogs.length !== 0 &&
                                                        latest_blogs.map((latest_blog) => (
                                                            <div className="tips-wrap mb-4" key={"latest_blogs_" + latest_blog.post_ID}>
                                                                <div className="d-flex  p-3">
                                                                    <div className="symbol symbol-100px mb-2">
                                                                        <span className="symbol-label avtars-outer mt-1 w-100">
                                                                            {latest_blog.post_thumbnail_url ? (
                                                                                <img src={latest_blog.post_thumbnail_url} className="img-fluid w-100" alt="Pic" />
                                                                            ) : (
                                                                                <img src="assets/images/training.png" className="img-fluid" alt="Pic" />
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                                        <div className="flex-grow-1">
                                                                            <span className="fw-normal fs-8 color1 d-flex">{renderHTML(latest_blog.post_reading_time)} mins read</span>
                                                                            <p className="fs-7 fw-bold-600 mb-1">{latest_blog.post_title}</p>
                                                                            <div className="fs-9 text-gray-700">
                                                                                <Link target="_blank" to={{ pathname: latest_blog.post_link }} className="fs-7 text-pink fw-bold-600">
                                                                                    Read More <span className="icon-right-arrow fs-9 ps-1 more-icon"></span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                                    {bidcreate_global_activetab === "tips" && (
                                        <div
                                            id="kt_activity_tips"
                                            className={
                                                "card-body p-0 tab-pane fade active show" + (bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")
                                            }
                                        >
                                            <div className="scroll-y right-scroll-height">
                                                <div className="py-5 px-3">
                                                    <div className="d-flex align-items-center position-relative search-border search-bids">
                                                        <span className="svg-icon svg-icon-3 position-absolute me-2">
                                                            <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            className="form-control form-control-sm ps-7"
                                                            onChange={(event) => {
                                                                this.filterMedia(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <h6 className="text-pink fs-6 mb-4 mt-4">Bid Documents</h6>
                                                    {tender_media.length !== 0 &&
                                                        tender_media.map((media) => (
                                                            <div className="bid_tender_document" key={"bid_document_key_" + media.response_media_id}>
                                                                <div className="row">
                                                                    <div className="col-sm-2">
                                                                        <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                            {(media.media_type.indexOf("jpeg") !== -1 || media.media_type.indexOf("png") !== -1 || media.media_type.indexOf("gif") !== -1) && (
                                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                    <img width="100%" src={media?.avatar} alt={media?.avatar_title} title={media?.avatar_title} />
                                                                                </div>
                                                                            )}
                                                                            {media.media_type.indexOf("pdf") !== -1 && (
                                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                    <i className="text-grey-dark icon-PDF fs-3x" aria-hidden="true"></i>
                                                                                </div>
                                                                            )}
                                                                            {media.media_type.indexOf("msword") !== -1 && (
                                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                    <i className="text-grey-dark icon-DOC fs-3x" aria-hidden="true"></i>
                                                                                </div>
                                                                            )}
                                                                            {media.media_type.indexOf("spreadsheetml") !== -1 && (
                                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                    <i className="text-grey-dark icon-XLS fs-3x" aria-hidden="true"></i>
                                                                                </div>
                                                                            )}
                                                                            {media.media_type.indexOf("officedocument") !== -1 && media.media_type.indexOf("spreadsheetml") === -1 && (
                                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                    <i className="text-grey-dark icon-DOC fs-3x" aria-hidden="true"></i>
                                                                                </div>
                                                                            )}
                                                                            {media.media_type.indexOf("excel") !== -1 && (
                                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                    <i className="text-grey-dark icon-xls-new fs-3x" aria-hidden="true"></i>
                                                                                </div>
                                                                            )}
                                                                            {media.media_type.indexOf("csv") !== -1 && (
                                                                                <div className="align_font mb-1 thumbnail_view_item">
                                                                                    <i className="text-grey-dark icon-csv-new fs-3x" aria-hidden="true"></i>
                                                                                </div>
                                                                            )}
                                                                            {media.media_type.indexOf("html") !== -1 && (
                                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                    <i className="text-grey-dark icon-HTML fs-3x" aria-hidden="true"></i>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-10">
                                                                        <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                            <div className="card-title align-items-start flex-column ms-1 d-flex flex-grow-1">
                                                                                <span className="success-title card-label fw-bold-700 fs-7 ">
                                                                                    {media?.avatar_title}
                                                                                </span>
                                                                                <span className="mt-1 fw-normal text-gray fs-8" style={{ "width": "10em", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>
                                                                                    {userActions.bytesToSize(media?.media_meta?.media_size)} {media?.media_meta?.media_type.split("/").pop().toUpperCase()}
                                                                                </span>
                                                                                <span className="mt-3 fw-normal text-gray fs-7 bid_tender_document_action_container">
                                                                                    {(media?.media_type.indexOf("jpeg") !== -1 || media?.media_type.indexOf("png") !== -1 || media?.media_type.indexOf("gif") !== -1 || media?.media_type.indexOf("pdf") !== -1 ) && <><span className="curser_pointer" onClick={(event) => {
                                                                                        this.props.ViewResponseMedia(media);
                                                                                    }}>View</span>
                                                                                        &nbsp;|&nbsp;
                                                                                    </>}
                                                                                    <span className="curser_pointer" onClick={() => {
                                                                                        this.downloadDocument(media);
                                                                                    }}>Download</span>
                                                                                    {this.checkOwner(user_info) &&
                                                                                        <><span>&nbsp;|&nbsp;</span>
                                                                                            <span className="curser_pointer" onClick={(event) => {
                                                                                                this.props.BidRemoveDocument(event, media);
                                                                                            }}>Delete</span></>}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    {this.checkOwner(user_info) && <ButtonDropzone
                                                        multiple={false}
                                                        rightSideButton={true}
                                                        // file_types={["application/pdf"]}
                                                        // inputaccept={"application/pdf"}
                                                        FileUploadHandleChange={(files) => {
                                                            this.props.BidUploadDocument(files[0], true);
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {bidcreate_rightsidetab_activetab === "section" && (
                            <div className={"card-body p-0 tab-pane fade section-content" + (bidcreate_rightsidetab_activetab === "section" ? " show active" : "")}>
                                <ul className="right-sidebar-icons nav nav-tabs border-bottom-0 inner-nav-tabs nav-line-tabs nav-stretch" role="tablist">
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_section_team_tab"
                                            className={"nav-link justify-content-center text-gray-400" + (bidcreate_section_activetab === "section_team" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_section_activetab: "section_team" });
                                            }}
                                        >
                                            <i className="icon-team team-icon1"></i> Team
                                        </span>
                                    </li>
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_section_library_tab"
                                            className={"nav-link justify-content-center text-active-gray-800" + (bidcreate_section_activetab === "section_library" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_section_activetab: "section_library" });
                                            }}
                                        >
                                            <i className="icon-book"></i> Library
                                        </span>
                                    </li>
                                    <li className="nav-item curser_pointer">
                                        <span
                                            id="kt_section_training_tab"
                                            className={"nav-link justify-content-center text-active-gray-800 training-btn" + (bidcreate_section_activetab === "section_training" ? " active" : "")}
                                            onClick={(event) => {
                                                this.setState({ bidcreate_section_activetab: "section_training" });
                                            }}
                                        >
                                            <i className="icon-training"></i> Training
                                        </span>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    {bidcreate_section_activetab === "section_team" && (
                                        <div id="kt_section_team" className="card-body p-0 tab-pane fade active show">
                                            <div className="scroll-y right-scroll-height right-section-outer">
                                                <div className="p-5 section-title fw-bolder text-white">{bid_section_selected.title}</div>
                                                <div className="py-5 px-3 border-bottom">
                                                    <h6 className="text-purple fs-6 mb-3">Section Status</h6>
                                                    {bid_section_selected && bid_section_selected.section_status && (
                                                        <div className="dropdown">
                                                            <div
                                                                className={
                                                                    "w-125px fs-6 border progress-text p-2 rounded-50 d-flex align-items-center curser_pointer title_vector_" +
                                                                    bid_section_selected.section_status.replace(" ", "_").toLowerCase()
                                                                }
                                                                id="bid_response_sidebar_section_status"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <span className="svg-icon-5 me-1">
                                                                    <i className={"icon_vector_" + bid_section_selected.section_status.replace(" ", "_").toLowerCase()}></i>
                                                                </span>
                                                                {bid_section_selected.section_status}
                                                            </div>
                                                            <div className="dropdown-menu status-response sect-dropdown" aria-labelledby="bid_response_sidebar_section_status">
                                                                <span className="fs-6 dropdown-item section_status_unassigned" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "Unassigned")}>
                                                                    Unassigned
                                                                </span>
                                                                <span className="fs-6 dropdown-item section_status_assigned" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "Assigned")}>
                                                                    Assigned
                                                                </span>
                                                                <span className="fs-6 dropdown-item section_status_in_progress" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "In progress")}>
                                                                    In progress
                                                                </span>
                                                                <span className="fs-6 dropdown-item section_status_for_review" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "For review")}>
                                                                    For review
                                                                </span>
                                                                <span className="fs-6 dropdown-item section_status_completed" onClick={(event) => this.props.BidSelectedStatusUpdate(event, bid_section_selected, "Completed")}>
                                                                    Completed
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="py-5 px-3 border-bottom sidebar-calender">
                                                    <h6 className="text-purple fs-6 mb-2">Due Dates</h6>
                                                    <div className="mb-2">
                                                        <span className="fs-7 d-flex justify-content-between text-gray-400 fw-bold-600">
                                                            Section due date
                                                            {this.state.section_duedate_editbale ? (
                                                                <i
                                                                    className="icon-cross-2 text-purple mb-3 cursor-pointer"
                                                                    onClick={(event) => {
                                                                        this.setState({
                                                                            section_duedate_editbale: false,
                                                                        });
                                                                    }}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="icon-edit text-purple mb-3 cursor-pointer"
                                                                    onClick={(event) => {
                                                                        this.setState({
                                                                            section_duedate_editbale: true,
                                                                        });
                                                                    }}
                                                                ></i>
                                                            )}
                                                        </span>
                                                        {this.state.section_duedate_editbale ? (
                                                            <DatePicker
                                                                peekNextMonth
                                                                // showTimeSelect
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                minDate={new Date()}
                                                                timeFormat="HH:mm"
                                                                dateFormat="dd/MM/yyyy"
                                                                dropdownMode="select"
                                                                className="form-control date-picker-outer"
                                                                selected={bid_section_selected.section_due_date ? new Date(bid_section_selected.section_due_date) : ""}
                                                                placeholderText="Select section due date"
                                                                onChange={(event) => {
                                                                    this.props.BidSectionDueDateUpdate(event, bid_section_selected);
                                                                }}
                                                            />
                                                        ) : (
                                                            <span className="fs-7 d-block text-gray-400 ">{bid_section_selected.section_due_date ? moment(bid_section_selected.section_due_date).format("DD/MM/YYYY") : "-"}</span>
                                                        )}
                                                    </div>
                                                    <div className="mb-0">
                                                        <span className="fs-7 d-block text-gray-400 fw-bold-600">Overall bid due date:</span>
                                                        <span className="fs-7 d-block text-gray-400 ">{bid_detail.submission_date ? moment(bid_detail.submission_date).format("DD/MM/YYYY") : "-"}</span>
                                                    </div>
                                                </div>
                                                <div className="py-5 px-3 border-bottom">
                                                    <h6 className="text-purple fs-6 mb-2">Section Assignees</h6>
                                                    <span className="fs-7 pb-2 d-block text-gray-400 ">
                                                        Team ({bid_section_selected.response_user && bid_section_selected.response_user.section_teams !== null ? bid_section_selected.response_user.section_teams.length : 0}):
                                                    </span>
                                                    <div className="symbol-group symbol-hover mb-4">
                                                        {bid_section_selected.response_user &&
                                                            bid_section_selected.response_user.section_teams &&
                                                            bid_section_selected.response_user.section_teams.map((section_team) => {
                                                                return (
                                                                    <div className="custom-tooltip" key={"section_team_" + section_team.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {section_team && section_team.avatar ? (
                                                                                <span className="symbol_img symbol-label">
                                                                                    <img src={section_team.avatar} alt={section_team.first_name} />
                                                                                    <span className="custom-tooltip-text ">{section_team.first_name + " " + section_team.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                                                                                    {section_team.first_name?.slice(0, 1) + section_team.last_name?.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text ">{section_team.first_name + " " + section_team.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
                                                            <span
                                                                className="symbol-label bg-secondary-dark text-inverse-primary fw-bolder"
                                                                onClick={() => {
                                                                    this.props.section_team_modal_action(true);
                                                                }}
                                                            >
                                                                <img src="assets/images/icons/plus.svg" title="" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span className="fs-7 pb-2 d-block text-gray-400 ">
                                                        Managers ({bid_section_selected.response_user && bid_section_selected.response_user.section_owners !== null ? bid_section_selected.response_user.section_owners.length : 0}):
                                                    </span>
                                                    <div className="symbol-group symbol-hover mb-4">
                                                        {bid_section_selected.response_user &&
                                                            bid_section_selected.response_user.section_owners &&
                                                            bid_section_selected.response_user.section_owners.map((section_owner) => {
                                                                return (
                                                                    <div className="custom-tooltip" key={"section_owner_" + section_owner.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {section_owner && section_owner.avatar ? (
                                                                                <span className="symbol_img symbol-label">
                                                                                    <img src={section_owner.avatar} alt={section_owner.first_name} />
                                                                                    <span className="custom-tooltip-text ">{section_owner.first_name + " " + section_owner.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                                                                                    {section_owner.first_name?.slice(0, 1) + section_owner.last_name?.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text ">{section_owner.first_name + " " + section_owner.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="" data-bs-original-title="Susan Redwood">
                                                            <span
                                                                className="symbol-label bg-secondary-dark text-inverse-primary fw-bolder"
                                                                onClick={() => {
                                                                    this.props.section_owner_modal_action(true);
                                                                }}
                                                            >
                                                                <img src="assets/images/icons/plus.svg" title="" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="py-5 px-3">
                                                    <div className="d-flex mb-3 justify-content-between align-items-center">
                                                        <h6 className="text-purple fs-6 m-0">Comments</h6>
                                                        <span
                                                            className="curser_pointer"
                                                            onClick={() => {
                                                                this.props.sectioncomment_sidebar_action(true);
                                                            }}
                                                        >
                                                            <span className="icon-message1 text-purple"></span>
                                                        </span>
                                                    </div>
                                                    {bid_detail.bid_section_comment[bid_section_selected.section_id] &&
                                                        bid_detail.bid_section_comment[bid_section_selected.section_id].map((section_comment, comment_key) => {
                                                            return (
                                                                <div className="d-flex mb-5" key={"comment_" + section_comment.comment.comment_id}>
                                                                    <div className="symbol symbol-30px me-3">
                                                                        <img title="user" alt="user" src={section_comment.comment.user.avatar ? section_comment.comment.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                                    </div>
                                                                    <div className="d-flex flex-column flex-row-fluid">
                                                                        <div className="d-flex align-items-center flex-wrap mb-1">
                                                                            <span className="text-grey-dark text-hover-primary fw-bold-700 me-2 fs-7">
                                                                                {section_comment.comment.user.first_name} {section_comment.comment.user.last_name}
                                                                            </span>
                                                                            <span className="text-grey-dark time-date-wrap fs-7">{moment(section_comment.comment.comment_date).fromNow()}</span>
                                                                        </div>
                                                                        <span className="text-grey-dark fs-7 fw-normal pb-2">{renderHTML(section_comment.comment.comment_text)}</span>
                                                                        <span
                                                                            className="text-purple fw-bold fs-7 curser_pointer"
                                                                            onClick={(event) => {
                                                                                this.props.BidSectionCommentReply(event, section_comment);
                                                                            }}
                                                                        >
                                                                            <i className="text-purple icon-Reply me-2"></i>
                                                                            {section_comment.comment.reply_count} replies
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {bidcreate_section_activetab === "section_library" && (
                                        <div id="kt_section_library" className="card-body p-0 tab-pane fade right-side-comment active show">
                                            <div className="scroll-y right-scroll-height">
                                                <div className="p-5 section-title fw-bolder text-white">{bid_section_selected.title}</div>

                                                <div className="p-5 border-bottom content-search">
                                                    <h6 className="text-purple fs-6 mb-2">Content</h6>
                                                    <div className="d-flex align-items-center position-relative search-bids">
                                                        <span className="svg-icon svg-icon-3 position-absolute me-2 search-bid-icon">
                                                            <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search content library"
                                                            className="form-control form-control-sm ps-9 search-box"
                                                            onChange={(event) => {
                                                                this.GetLibraryWithContents(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="aside-menu flex-column-fluid  my-8  right-sidebar-menu">
                                                        <div className="w-100 hover-scroll-overlay-y d-flex pe-2">
                                                            <div className="menu menu-column menu-rounded fw-bold p-0">
                                                                {library_conents &&
                                                                    library_conents.map((library, library_key) => {
                                                                        return (
                                                                            <div className="menu-item menu-accordion" key={"library_key_" + library_key}>
                                                                                <span className="menu-link p-0 ">
                                                                                    <span className="menu-title ps-2 hide_show_action" parent_id={"library_" + library.library_id}>
                                                                                        <span className="menu-arrow"></span>
                                                                                        {library.name}
                                                                                    </span>
                                                                                </span>
                                                                                {library.library_contents && (
                                                                                    <div className="menu-sub menu-sub-accordion" id={"library_" + library.library_id}>
                                                                                        {library.library_contents.map((content, content_key) => {
                                                                                            return (
                                                                                                <div className="menu-item" key={"content_key_" + content_key}>
                                                                                                    <span className="menu-link py-1">
                                                                                                        <span className="menu-bullet">
                                                                                                            <span className="bullet bullet-dot"></span>
                                                                                                        </span>
                                                                                                        <span
                                                                                                            className="menu-title ps-2 py-1"
                                                                                                            onClick={(event) => {
                                                                                                                this.props.onLibraryContentCountUpdate(content.content_id);
                                                                                                                this.props.UpdateSectionWithLibraryContent(event, content);
                                                                                                            }}
                                                                                                        >
                                                                                                            {content.content_name}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                    <div className="p-5 border-bottom content-search">
                                                        <h6 className="text-purple fs-6 mb-2">Past proposals</h6>
                                                        <div className="d-flex align-items-center position-relative search-bids">
                                                            <span className="svg-icon svg-icon-3 position-absolute me-2 search-bid-icon">
                                                                <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                                                            </span>
                                                            <input
                                                                type="text"
                                                                placeholder="Search from past proposals"
                                                                className="form-control form-control-sm ps-9 search-box"
                                                                onChange={(event) => {
                                                                    this.GetLibraryPastProposals(event.target.value);
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="aside-menu flex-column-fluid  my-8  right-sidebar-menu">
                                                            <div className="w-100 hover-scroll-overlay-y d-flex pe-2">
                                                                <div className="menu menu-column menu-rounded fw-bold p-0">
                                                                    {library_proposals &&
                                                                        library_proposals.map((proposal, proposal_key) => {
                                                                            return (
                                                                                <div className="menu-item menu-accordion" key={"proposal_key_" + proposal_key}>
                                                                                    <span className="menu-link p-0 ">
                                                                                        <span className="menu-title ps-2 hide_show_action" parent_id={"proposal_key_" + proposal.bid_id}>
                                                                                            <span className="menu-arrow"></span>
                                                                                            {proposal.bid_title}
                                                                                        </span>
                                                                                    </span>
                                                                                    {proposal.responses && (
                                                                                        <div className="menu-sub menu-sub-accordion" id={"proposal_key_" + proposal.bid_id}>
                                                                                            {proposal.responses.map((past_proposal, response_key) => {
                                                                                                return (
                                                                                                    <div className="menu-item" key={"bid_proposal_key_" + response_key}>
                                                                                                        <span className="menu-link py-1">
                                                                                                            <span className="menu-bullet">
                                                                                                                <span className="bullet bullet-dot"></span>
                                                                                                            </span>
                                                                                                            <span className="menu-title ps-2 py-1" onClick={(event) => this.props.AddNewBidproposal(event, past_proposal)}>
                                                                                                                {past_proposal.title}
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {bidcreate_section_activetab === "section_training" && (
                                        <div id="kt_section_training" className="card-body p-0 tab-pane fade active show">
                                            <div className="scroll-y right-scroll-height">
                                                <div className="p-5 section-title fw-bolder text-white">{bid_section_selected.title}</div>

                                                <div className="p-5 border-bottom content-search">
                                                    <h6 className="text-purple fs-6 mb-2">Demo Content</h6>
                                                    <div className="text-gray-400 fs-7 text-hover-danger curser_pointer fw-bold-600">
                                                        <span className="d-flex align-items-center insert-icon" onClick={(event) => this.props.BidtypeDemoContentAvailable(event, bid_section_selected)}>
                                                            <i className="fa fa-plus fs-8 me-2 text-gray-400 " aria-hidden="true"></i> Insert demo content
                                                        </span>
                                                    </div>
                                                </div>

                                                {(bid_section_selected?.bidtype_content?.demo_video || bid_section_selected?.bidtype_content?.demo_video_description) && (
                                                    <div className="p-5">
                                                        <figure>
                                                            {bid_section_selected?.bidtype_content?.demo_video && (
                                                                <div className="row">
                                                                    <ReactPlayer loop={true} controls={true} width="300px" height="100%" className="react-player" url={bid_section_selected?.bidtype_content?.demo_video} />
                                                                </div>
                                                            )}

                                                            {bid_section_selected?.bidtype_content?.demo_video_description && (
                                                                <div className="demo_video_description mt-3">
                                                                    <figcaption>{renderHTML(bid_section_selected?.bidtype_content?.demo_video_description)}</figcaption>
                                                                </div>
                                                            )}
                                                        </figure>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetBidActivityLogs: userActions.GetBidActivityLogs,
    onGetLatestBidHQ: userActions.GetLatestBidHQ,
    onGetLibraryPastProposals: userActions.GetLibraryPastProposals,
    onGetLibraryWithContents: userActions.GetLibraryWithContents,
    onLibraryContentCountUpdate: userActions.LibraryContentCountUpdate,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BidRightSideBar);