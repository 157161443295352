import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { notify } from "react-notify-toast";
import NumberFormat from "react-number-format";

import * as userActions from "../../../actions/index";
import Validations from "../../../components/Utility/Validations";
class BillingOverviewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billingoverview_form: {
                company_name: {
                    type: "text",
                    label: "Company name*",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Company name",
                    validations: { required: true },
                },
                abn: {
                    type: "text",
                    label: "ABN*",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter ABN",
                    validations: {
                        required: true,
                        min_length: 11,
                        ValidateABN: true,
                        NumberAndSpace: true,
                    },
                },
                country: {
                    label: "Country*",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "country_opk0", name: "Select Country", value: "" }],
                },
                subscription_package_id: {
                    label: "subscription_package_id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                user_limits: {
                    value: 0,
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Users",
                    placeholder: "",
                    validations: { required: false },
                },
                cardnumber: {
                    value: "",
                    valid: true,
                    type: "numberformat",
                    error_msg: "",
                    label: "Credit Card Number",
                    placeholder: "0000 0000 0000 0000",
                    validations: { required: true, CreditCardNumber: true },
                },
                expiry_date: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Expiry Month",
                    placeholder: "MM/YY",
                    validations: { required: true, CreditCardExpiry: true, min_length: 5 },
                },
                cvc: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "CVV",
                    placeholder: "•••",
                    validations: { required: true, CreditCardCVV: true },
                },
                card_holdername: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Cardholder name",
                    placeholder: "Enter cardholder’s full name as appear on card",
                    validations: { required: true },
                },
                subscription_price: {
                    value: 0,
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "",
                    placeholder: "",
                    validations: { required: true },
                },
                package_feature_id: {
                    label: "package_feature_id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                extrasubsciption_users: {
                    label: "extrasubsciption_users",
                    type: "hidden",
                    value: 0,
                    valid: true,
                    error_msg: "",
                    validations: {
                        required: false,
                    },
                },
                subscription_discount_id: {
                    label: "subscription_discount_id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: {
                        required: false,
                    },
                },
            },
            payment_bycard: true,

            next_billing_date: "",
            payment_information: [],
            subscription_packages: [],
            subscription_package_selected: "",

            extrasubsciption_users: 0,
            subscription_package_plans: "",

            discount_code: false,
            subscription_discount: [],
            firstSetup: false
        };
    }

    async componentDidMount() {
        await this.GetCountriesLists();
        let payment_information = this.props.payment_information;
        let subscription_packages = this.props.subscription_packages;
        let subscription_package_selected = this.props.subscription_package_selected;

        const update_billingoverview_form = { ...this.state.billingoverview_form };
        update_billingoverview_form.subscription_package_id.value = subscription_package_selected.subscription_package_id;
        update_billingoverview_form.user_limits.value = subscription_package_selected.user_limits;
        update_billingoverview_form.subscription_price.value = subscription_package_selected.price;

        if (payment_information.length !== 0) {
            update_billingoverview_form.cardnumber.validations.required = false;
            update_billingoverview_form.cardnumber.validations.CreditCardNumber = false;
            update_billingoverview_form.expiry_date.validations.required = false;
            update_billingoverview_form.expiry_date.validations.CreditCardExpiry = false;
            update_billingoverview_form.expiry_date.validations.min_length = false;
            update_billingoverview_form.cvc.validations.required = false;
            update_billingoverview_form.cvc.validations.CreditCardCVV = false;
            update_billingoverview_form.card_holdername.validations.required = false;
        }

        let next_billing_date = "";
        if (subscription_package_selected.billing_cycle === "month") {
            next_billing_date = moment().add(1, "months");
        } else {
            next_billing_date = moment().add(1, "years");
        }

        if (!this.props.company_info || !this.props.company_info.company_name) {
            this.setState({ firstSetup: true });
        }

        update_billingoverview_form.company_name.value = this.props.company_info?.company_name ? this.props.company_info.company_name : "";
        update_billingoverview_form.abn.value = this.props.company_info?.abn ? this.props.company_info.abn : "";

        if (this.props.company_info?.country) {
            update_billingoverview_form.abn.label = this.props.company_info.country === "NZ" ? "NZ GST*" : "ABN*";
            update_billingoverview_form.abn.placeholder = this.props.company_info.country === "NZ" ? "Enter NZ GST" : "Enter ABN";
        }

        update_billingoverview_form.country.value = this.props.company_info?.country ? this.props.company_info.country : "";

        this.setState({
            next_billing_date: next_billing_date,
            payment_information: payment_information,
            subscription_packages: subscription_packages,
            billingoverview_form: update_billingoverview_form,
            subscription_package_selected: subscription_package_selected,
            payment_bycard: payment_information.length !== 0 ? false : true,
            subscription_package_plans: subscription_packages[subscription_package_selected.product_id],
        });

        await this.BetaDiscountCouponVerify();
        await this.CalculateSubscriptionBilling();
    }

    BillingOverviewReload() {
        let payment_information = this.props.payment_information;
        let subscription_packages = this.props.subscription_packages;
        let subscription_package_selected = this.props.subscription_package_selected;

        const update_billingoverview_form = { ...this.state.billingoverview_form };
        update_billingoverview_form.subscription_package_id.value = subscription_package_selected.subscription_package_id;
        update_billingoverview_form.user_limits.value = subscription_package_selected.user_limits;
        update_billingoverview_form.subscription_price.value = subscription_package_selected.price;
        update_billingoverview_form.package_feature_id.value = "";
        update_billingoverview_form.extrasubsciption_users.value = 0;

        if (payment_information.length !== 0) {
            update_billingoverview_form.cardnumber.validations.required = false;
            update_billingoverview_form.cardnumber.validations.CreditCardNumber = false;
            update_billingoverview_form.expiry_date.validations.required = false;
            update_billingoverview_form.expiry_date.validations.CreditCardExpiry = false;
            update_billingoverview_form.expiry_date.validations.min_length = false;
            update_billingoverview_form.cvc.validations.required = false;
            update_billingoverview_form.cvc.validations.CreditCardCVV = false;
            update_billingoverview_form.card_holdername.validations.required = false;
        }

        let extrasubsciption_users = this.props.extrasubsciption_users;
        let subscription_package_feature = subscription_package_selected.subscription_package_feature;
        if (subscription_package_feature && subscription_package_feature.title === "additional_users" && extrasubsciption_users !== 0) {
            // update_billingoverview_form.package_feature_id.value = subscription_package_feature.package_feature_id;
            update_billingoverview_form.package_feature_id.value = subscription_package_feature.package_feat;
            update_billingoverview_form.extrasubsciption_users.value = extrasubsciption_users;
            update_billingoverview_form.subscription_price.value = parseInt(subscription_package_selected.price) + parseInt(subscription_package_feature.unit_amount * extrasubsciption_users);
        }

        let next_billing_date = "";
        if (subscription_package_selected.billing_cycle === "month") {
            next_billing_date = moment().add(1, "months");
        } else {
            next_billing_date = moment().add(1, "years");
        }

        this.setState(
            {
                next_billing_date: next_billing_date,
                payment_information: payment_information,
                extrasubsciption_users: extrasubsciption_users,
                billingoverview_form: update_billingoverview_form,
                subscription_package_selected: subscription_package_selected,
                subscription_package_plans: subscription_packages[subscription_package_selected.product_id],
            },
            async () => {
                await this.CalculateSubscriptionBilling();
            }
        );
    }

    BetaDiscountCouponVerify = async () => {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

        let current_date = moment();
        let current_subscription = user_info?.subscription;
        let end_date = current_subscription?.subscription_billing?.end_date;
        let subscription_expired = moment(end_date).diff(current_date, "hours");

        if (subscription_expired >= 0) {
            await this.props.onVerifyDiscount("BETA50").then((response) => {
                if (response.success === true) {
                    let subscription_discount = response.data;
                    const update_billingoverview_form = { ...this.state.billingoverview_form };
                    update_billingoverview_form.subscription_discount_id.value = subscription_discount.subscription_discount_id;

                    this.setState({
                        discount_code: true,
                        subscription_discount: response.data,
                        billingoverview_form: update_billingoverview_form,
                    });
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    CalculateSubscriptionBilling = () => {
        let subscription_discount = this.state.subscription_discount;
        let extrasubsciption_users = this.state.extrasubsciption_users;
        let subscription_package_selected = this.state.subscription_package_selected;
        let subscription_package_feature = subscription_package_selected.subscription_package_feature;

        const update_billingoverview_form = { ...this.state.billingoverview_form };
        let subscription_price = parseInt(subscription_package_selected.price) + parseInt(subscription_package_feature.unit_amount * extrasubsciption_users);

        if (subscription_discount?.percent) {
            subscription_price = (parseInt(subscription_price) / 100) * parseInt(subscription_discount.percent);
        }

        update_billingoverview_form.subscription_price.value = (Math.round(subscription_price * 10) / 10);
        this.setState({
            billingoverview_form: update_billingoverview_form,
        });
    };

    inputChangebillingCycle = (event, identifier) => {
        let subscription_package_selected = this.props.subscription_package_selected;
        let subscription_packages = this.props.subscription_packages;
        if (identifier === "year") {
            this.props.billingcyclechange_action(subscription_packages[subscription_package_selected.product_id].year);
        }
        if (identifier === "month") {
            this.props.billingcyclechange_action(subscription_packages[subscription_package_selected.product_id].month);
        }
    };

    inputChangeHandler(event, identifier) {
        const update_billingoverview_form = { ...this.state.billingoverview_form };
        const form_element = { ...update_billingoverview_form[identifier] };

        if (form_element.type === "numberformat") {
            form_element.value = event;
        } else if (form_element.validations.CreditCardExpiry) {
            let prevValue = form_element.value;
            let currentValue = event.target.value;
            let textTemp = currentValue;
            let current_year = moment().format("YY");

            if (textTemp[0] !== "0" && textTemp[0] !== "1" && textTemp[0] !== "2") {
                textTemp = "";
            } else if (prevValue?.length === 3 && currentValue.length === prevValue.length - 1) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 4 && textTemp[3] < current_year[0]) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 5 && textTemp[4] < current_year[1]) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 6) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 2) {
                if (parseInt(textTemp?.substring(0, 2)) > 12 || parseInt(textTemp?.substring(0, 2)) === 0) {
                    textTemp = textTemp?.slice(0, -1);
                } else if (textTemp?.length === 2) {
                    textTemp += "/";
                } else {
                    textTemp = textTemp[0];
                }
            }
            form_element.value = textTemp;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_billingoverview_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_billingoverview_form) {
            is_form_valid = update_billingoverview_form[identifier].valid && is_form_valid;
        }

        if (identifier === "country") {
            update_billingoverview_form.abn.value = "";
            update_billingoverview_form.abn.label = event.target.value === "NZ" ? "NZ GST*" : "ABN*";
            update_billingoverview_form.abn.placeholder = event.target.value === "NZ" ? "Enter NZ GST" : "Enter ABN";
        }

        this.setState({
            billingoverview_form: update_billingoverview_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_billingoverview_form = this.state.billingoverview_form;
        for (let key in update_billingoverview_form) {
            let form_element = update_billingoverview_form[key];

            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_billingoverview_form[key] = form_element;
        }
        this.setState({
            billingoverview_form: update_billingoverview_form,
        });

        if (is_form_valid) {
            const form_data = {};
            const company_form_data = {};
            for (let key in this.state.billingoverview_form) {
                if (key === 'company_name' || key === 'abn' || key === 'country') {
                    company_form_data[key] = this.state.billingoverview_form[key].value;
                } else {
                    form_data[key] = this.state.billingoverview_form[key].value;
                }
            }
            this.props.CompanyUpdate(company_form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");

                    let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                    user_info.company = response.company_info;
                    localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));
                    this.props.CreateSubscription(form_data);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    ChangePaymentMethod = (event) => {
        let payment_bycard = this.state.payment_bycard;
        const update_billingoverview_form = { ...this.state.billingoverview_form };

        if (payment_bycard) {
            update_billingoverview_form.cardnumber.validations.required = false;
            update_billingoverview_form.cardnumber.validations.CreditCardNumber = false;
            update_billingoverview_form.expiry_date.validations.required = false;
            update_billingoverview_form.expiry_date.validations.CreditCardExpiry = false;
            update_billingoverview_form.expiry_date.validations.min_length = false;
            update_billingoverview_form.cvc.validations.required = false;
            update_billingoverview_form.cvc.validations.CreditCardCVV = false;
            update_billingoverview_form.card_holdername.validations.required = false;
        } else {
            update_billingoverview_form.cardnumber.validations.required = true;
            update_billingoverview_form.cardnumber.validations.CreditCardNumber = true;
            update_billingoverview_form.expiry_date.validations.required = true;
            update_billingoverview_form.expiry_date.validations.CreditCardExpiry = true;
            update_billingoverview_form.expiry_date.validations.min_length = true;
            update_billingoverview_form.cvc.validations.required = true;
            update_billingoverview_form.cvc.validations.CreditCardCVV = true;
            update_billingoverview_form.card_holdername.validations.required = true;
        }
        this.setState({
            payment_bycard: payment_bycard ? false : true,
            billingoverview_form: update_billingoverview_form,
        });
    };

    GetCountriesLists = async () => {
        await userActions.GetCountriesLists().then((response) => {
            if (response.success === true) {
                let country_details = response.data;
                const update_billingoverview_form = { ...this.state.billingoverview_form };

                let country_option = [{ opk: "country_opk0", name: "Select Country", value: "" }];
                for (let country_detail of country_details) {
                    country_option.push({
                        opk: country_detail.country_code,
                        name: country_detail.country_name,
                        value: country_detail.country_code,
                    });
                }

                update_billingoverview_form.country.options = country_option;
                this.setState({ billingoverview_form: update_billingoverview_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const { billingoverview_form, subscription_packages, subscription_package_selected, subscription_package_plans, payment_bycard, payment_information, discount_code, subscription_discount } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="billingoverview_modal bid-custom-modal billing-modal"
                    show={this.props.billingoverview_modal}
                    onHide={() => {
                        this.props.billingoverview_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Billing Overview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="d-flex flex-column">
                                <div className="row divide-colum">
                                    <div className="col-lg-6 mb-10 mb-lg-0 left-colum pt-5">
                                        <h6 className="fw-bold">Upgrade to </h6>
                                        <div className="fw-normal mb-6 pulse subsc-icons-dropdown">
                                            <button type="button" className="btn w-100 dropdown-toggle border upgrade-btn ps-3" id="billing_subscription_package" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className={"me-3 icon-subscription package_name_" + subscription_package_selected?.package_name?.toLowerCase()}></i>
                                                {subscription_package_selected.package_name}
                                            </button>
                                            <div className="dropdown-menu menu-sub menu-sub-dropdown w-100 upgrade-menu p-2" aria-labelledby="billing_subscription_package">
                                                {subscription_packages &&
                                                    subscription_packages.map((subscription_package) => (
                                                        <div className="py-4 px-2 cursor-pointer link-hover" key={"subscription_package_" + subscription_package.product_detail.product_id}>
                                                            <div
                                                                className="text-black"
                                                                onClick={(event) => {
                                                                    this.props.ChangeSubscriptionPackage(subscription_package.product_detail.product_id);
                                                                }}
                                                            >
                                                                <i className={"icon-subscription me-3 package_name_" + subscription_package?.product_detail?.name?.toLowerCase()}></i>
                                                                <span>{subscription_package?.product_detail?.name}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <h6 className="fw-bold">Billing cycle</h6>
                                        <div className="nav flex-column">
                                            {subscription_package_plans.year && (
                                                <div className="billing-plan-choose h-45px nav-link btn btn-outline border btn-outline btn-color-dark btn-active  d-flex flex-stack text-start p-3 active mb-4">
                                                    <div className="d-flex align-items-center me-2">
                                                        <div className="form-check form-check-custom form-check-solid form-check-success me-3">
                                                            <input
                                                                type="radio"
                                                                id="billing_cycle_year"
                                                                className="form-check-input"
                                                                checked={subscription_package_selected.billing_cycle === "year" ? true : false}
                                                                onChange={(event) => this.inputChangebillingCycle(event, "year")}
                                                            />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div className="fw-bold color1" htmlFor="billing_cycle_year">
                                                                Annual{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-5">
                                                        <span className="mb-2 fs-7 fw-bold text-black">$</span>
                                                        <span className="fs-7 fw-bold text-black">{(parseInt(subscription_package_plans.year.price) / 100) * parseInt(50)}</span>
                                                        <span className="fs-7 fw-bold text-black">
                                                            /<span data-kt-element="period">{subscription_package_plans.year.billing_cycle}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            {subscription_package_plans.month && (
                                                <div className="billing-plan-choose h-45px nav-link btn btn-outline border btn-outline btn-color-dark btn-active  d-flex flex-stack text-start p-3 mb-8">
                                                    <div className="d-flex align-items-center me-2">
                                                        <div className="form-check form-check-custom form-check-solid form-check-success me-3">
                                                            <input
                                                                type="radio"
                                                                id="billing_cycle_month"
                                                                className="form-check-input"
                                                                checked={subscription_package_selected.billing_cycle === "month" ? true : false}
                                                                onChange={(event) => this.inputChangebillingCycle(event, "month")}
                                                            />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div className="fw-bold color1" htmlFor="billing_cycle_month">
                                                                Monthly{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-5">
                                                        <span className="mb-2 fs-7 fw-bold text-black">$</span>
                                                        <span className="fs-7 fw-bold text-black">{(parseInt(subscription_package_plans.month.price) / 100) * parseInt(50)}</span>
                                                        <span className="fs-7 fw-bold text-black">
                                                            /<span data-kt-element="period">{subscription_package_plans.month.billing_cycle}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <h6 className="fw-bold ">Users</h6>
                                        <div className=" border rounded d-flex flex-stack text-start p-3 mb-2 h-45px">
                                            <div className="d-flex align-items-center me-2">
                                                <div className="flex-grow-1">
                                                    <div className="fw-bold">
                                                        {billingoverview_form.user_limits.value} {billingoverview_form.user_limits.value > 1 ? " Users" : " User"}{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ms-5">
                                                <span className="mb-2 fs-7 fw-bold text-black">Included</span>
                                            </div>
                                        </div>
                                        {billingoverview_form.extrasubsciption_users.value !== 0 && (
                                            <div className=" border rounded d-flex flex-stack text-start p-3 mb-2 h-45px">
                                                <div className="d-flex align-items-center me-2">
                                                    <div className="flex-grow-1">
                                                        <div className="fw-bold">
                                                            {billingoverview_form.extrasubsciption_users.value} additional {billingoverview_form.extrasubsciption_users.value > 1 ? "users" : "user"}{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-5">
                                                    <span className="mb-2 fs-7 fw-bold-500">
                                                        AU${subscription_package_selected.subscription_package_feature ? subscription_package_selected.subscription_package_feature.unit_amount : 0} /user
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {subscription_package_selected && subscription_package_selected.subscription_package_feature && subscription_package_selected.subscription_package_feature.title === "additional_users" && (
                                            <button
                                                type="button"
                                                className="btn btn-white fw-normal p-0 fs-6 text-gray-new"
                                                onClick={(event) => {
                                                    this.props.billingaddadditionalusers_action(event);
                                                }}
                                            >
                                                <i className="fas fa-plus fs-8"></i> Add users
                                            </button>
                                        )}

                                        <div className=" border-top  py-4 mt-6">
                                            {/* <span
                                                className="text-purple w-100 d-flex mb-2 curser_pointer"
                                                onClick={(event) => {
                                                    this.setState({
                                                        discount_code: discount_code ? false : true,
                                                    });
                                                }}
                                            >
                                                Have a discount code?
                                            </span> */}

                                            {/* {discount_code &&
                                                (subscription_discount ? (
                                                    <div className=" border rounded d-flex flex-stack text-start p-3 mb-1 h-45px">
                                                        <div className="d-flex align-items-center me-2">
                                                            <div className="flex-grow-1">
                                                                <div className="fw-bold">{subscription_discount.code}</div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-5">
                                                            <span className="mb-2 fs-7 fw-bold text-green cursor-pointer">Applied!</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className=" border rounded d-flex flex-stack text-start p-3 mb-1 h-45px">
                                                        <div className="d-flex align-items-center me-2">
                                                            <div className="flex-grow-1">
                                                                <div className="fw-bold">Enter your code</div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-5">
                                                            <span className="mb-2 fs-7 fw-bold text-purple cursor-pointer">Apply</span>
                                                        </div>
                                                    </div>
                                                ))} */}
                                        </div>

                                        <div className=" border-top d-flex flex-stack text-start py-4 mt-3">
                                            <div className="d-flex align-items-center me-2">
                                                <div className="flex-grow-1">
                                                    <div className="fw-bolder color1 d-block ">Total </div>
                                                </div>
                                            </div>
                                            <div className="ms-5 text-right">
                                                <span className="fw-bolder color1 d-block ">AU$ {billingoverview_form.subscription_price.value}</span>
                                                <span className="text-gray-new fw-normal">
                                                    Billed {subscription_package_selected.billing_cycle === "month" ? "monthly" : "yearly"} on the {moment(this.state.next_billing_date).format("Do")} until cancellation
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 right-colum pt-5">
                                        <div className={"col-sm-12 col-lg-12 mb-2 fv-row" + (!billingoverview_form.company_name.valid && billingoverview_form.company_name.onBlur_out ? " error-field" : "")}>
                                            <label className="form-label fs-7">{billingoverview_form.company_name.label}</label>
                                            <input
                                                autoComplete="new_company_name"
                                                type={billingoverview_form.company_name.type}
                                                className="form-control form-control-lg form-control-solid"
                                                placeholder={billingoverview_form.company_name.placeholder}
                                                value={billingoverview_form.company_name.value ? billingoverview_form.company_name.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "company_name")}
                                                onBlur={(event) => {
                                                    billingoverview_form.company_name.onBlur_out = true;
                                                    this.setState({
                                                        billingoverview_form: billingoverview_form,
                                                    });
                                                }}
                                            />
                                            {!billingoverview_form.company_name.valid && billingoverview_form.company_name.onBlur_out && (
                                                <div className="error field-error">{billingoverview_form.company_name.error_msg}</div>
                                            )}
                                        </div>
                                        <div className="row mb-4">
                                            <div className={"col-lg-6" + (!billingoverview_form.country.valid && billingoverview_form.country.onBlur_out ? " error-field" : "")}>
                                                <label className="form-label fs-7">{billingoverview_form.country.label}</label>
                                                <select className="form-control form-control-lg form-control-solid form-select form-select-solid" value={billingoverview_form.country.value} onChange={(event) => this.inputChangeHandler(event, "country")}>
                                                    {billingoverview_form.country.options.map((option) => (
                                                        <option value={option.value} key={option.opk}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {!billingoverview_form.country.valid && billingoverview_form.country.onBlur_out && <div className="error field-error">{billingoverview_form.country.error_msg}</div>}
                                            </div>
                                            <div className={"col-lg-6" + (!billingoverview_form.abn.valid && billingoverview_form.abn.onBlur_out ? " error-field" : "")}>
                                                <label className="form-label fs-7">{billingoverview_form.abn.label}</label>
                                                <input
                                                    autoComplete="new_abn"
                                                    type={billingoverview_form.abn.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={billingoverview_form.abn.placeholder}
                                                    value={billingoverview_form.abn.value ? billingoverview_form.abn.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "abn")}
                                                    onBlur={(event) => {
                                                        billingoverview_form.abn.onBlur_out = true;
                                                        this.setState({
                                                            billingoverview_form: billingoverview_form,
                                                        });
                                                    }}
                                                />
                                                {!billingoverview_form.abn.valid && billingoverview_form.abn.onBlur_out && <div className="error field-error">{billingoverview_form.abn.error_msg}</div>}
                                            </div>
                                        </div>
                                        {payment_bycard ? (
                                            <>
                                                <div className={"d-flex flex-column mb-7 fv-row" + (!billingoverview_form.cardnumber.valid && billingoverview_form.cardnumber.onBlur_out ? " error-field" : "")}>
                                                    <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{billingoverview_form.cardnumber.label}</label>
                                                    <div className="position-relative">
                                                        <NumberFormat
                                                            inputMode="numeric"
                                                            format="#### #### #### ####"
                                                            placeholder={billingoverview_form.cardnumber.placeholder}
                                                            className="form-control form-control-lg form-control-solid"
                                                            value={billingoverview_form.cardnumber.value ? billingoverview_form.cardnumber.value : ""}
                                                            onValueChange={(values) => {
                                                                const { value } = values;
                                                                this.inputChangeHandler(value, "cardnumber");
                                                            }}
                                                            onBlur={(event) => {
                                                                billingoverview_form.cardnumber.onBlur_out = true;
                                                                this.setState({
                                                                    billingoverview_form: billingoverview_form,
                                                                });
                                                            }}
                                                        />
                                                        <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                            <img src="assets/images/icons/card.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    {!billingoverview_form.cardnumber.valid && billingoverview_form.cardnumber.onBlur_out ? <div className="error field-error">{billingoverview_form.cardnumber.error_msg}</div> : ""}
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className={"d-flex flex-column mb-7 fv-row" + (!billingoverview_form.expiry_date.valid && billingoverview_form.expiry_date.onBlur_out ? " error-field" : "")}>
                                                            <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{billingoverview_form.expiry_date.label}</label>
                                                            <div className="position-relative">
                                                                <input
                                                                    type={billingoverview_form.expiry_date.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={billingoverview_form.expiry_date.placeholder}
                                                                    value={billingoverview_form.expiry_date.value ? billingoverview_form.expiry_date.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "expiry_date")}
                                                                    onBlur={(event) => {
                                                                        billingoverview_form.expiry_date.onBlur_out = true;
                                                                        this.setState({
                                                                            billingoverview_form: billingoverview_form,
                                                                        });
                                                                    }}
                                                                />
                                                                <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                                    <img src="assets/images/icons/card-date.svg" alt="" />
                                                                </div>
                                                            </div>
                                                            {!billingoverview_form.expiry_date.valid && billingoverview_form.expiry_date.onBlur_out ? (
                                                                <div className="error field-error">{billingoverview_form.expiry_date.error_msg}</div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className={"d-flex flex-column mb-7 fv-row" + (!billingoverview_form.cvc.valid && billingoverview_form.cvc.onBlur_out ? " error-field" : "")}>
                                                            <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{billingoverview_form.cvc.label}</label>
                                                            <div className="position-relative">
                                                                <input
                                                                    type={billingoverview_form.cvc.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={billingoverview_form.cvc.placeholder}
                                                                    value={billingoverview_form.cvc.value ? billingoverview_form.cvc.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "cvc")}
                                                                    onBlur={(event) => {
                                                                        billingoverview_form.cvc.onBlur_out = true;
                                                                        this.setState({
                                                                            billingoverview_form: billingoverview_form,
                                                                        });
                                                                    }}
                                                                />
                                                                <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                                    <img src="assets/images/icons/cvc.svg" alt="" />
                                                                </div>
                                                            </div>
                                                            {!billingoverview_form.cvc.valid && billingoverview_form.cvc.onBlur_out ? <div className="error field-error">{billingoverview_form.cvc.error_msg}</div> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"d-flex flex-column mb-7 fv-row" + (!billingoverview_form.cvc.valid && billingoverview_form.cvc.onBlur_out ? " error-field" : "")}>
                                                    <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{billingoverview_form.card_holdername.label}</label>
                                                    <div className="position-relative">
                                                        <input
                                                            type={billingoverview_form.card_holdername.type}
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder={billingoverview_form.card_holdername.placeholder}
                                                            value={billingoverview_form.card_holdername.value ? billingoverview_form.card_holdername.value : ""}
                                                            onChange={(event) => this.inputChangeHandler(event, "card_holdername")}
                                                            onBlur={(event) => {
                                                                billingoverview_form.card_holdername.onBlur_out = true;
                                                                this.setState({
                                                                    billingoverview_form: billingoverview_form,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            payment_information.map(
                                                (information, information_key) =>
                                                    information.default_card && (
                                                        <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-5 plan-pro" key={"payment_information_" + information_key}>
                                                            <div className="me-5">
                                                                <div className="symbol-100px symbol-fixed position-relative">
                                                                    <img src={"assets/images/icons/card-img/card-" + information.brand + ".svg"} alt="card" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                    <div className="d-flex flex-column">
                                                                        <h4 className="text-gray-new mb-1 fs-8">Credit or debit card</h4>
                                                                        <p className="text-gray-new mb-0 fs-6">
                                                                            •••• •••• •••• {information.last4} (Expires {information.exp_month}/{information.exp_year})
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="edit-btn d-flex align-items-center">
                                                                <h5 className={"mb-0 fs-7 text-dark-blue-light"}>Primary</h5>
                                                            </div>
                                                        </div>
                                                    )
                                            )
                                        )}
                                        {payment_information.length !== 0 && (
                                            <div className="btn btn-sm btn-primary rounded-50 ms-4 px-10 hvr-grow" onClick={(event) => this.ChangePaymentMethod(event)}>
                                                {payment_bycard ? "Payment with primary card" : "Add new card"}
                                            </div>
                                        )}
                                        <div className="d-flex flex-row-fluid justify-content-end confirm-button-div">
                                            <button type="submit" className="btn light-btn-info btn-info rounded-50 px-10 hvr-grow">
                                                Confirm payment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onVerifyDiscount: userActions.VerifyDiscount,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BillingOverviewModal);