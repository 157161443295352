import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
class HeaderNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userdesktop_notifications: [],
        };
    }

    componentDidMount() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        if (user_info && user_info.user && user_info.company) {
            this.GetUserDesktopNotification();

            if (process.env.REACT_APP_ENV !== "developer") {
                const interval = setInterval(() => {
                    if (user_info && user_info.user && user_info.company) {
                        this.GetUserDesktopNotification();
                    }
                }, 3000);
                return () => clearInterval(interval);
            }
        }
    }

    GetUserDesktopNotification = async () => {
        this.props.onGetUserDesktopNotification().then((response) => {
            if (response.success === true) {
                this.setState({ userdesktop_notifications: response.data });
            } else {
                // notify.show(response.message, "error");
            }
        });
    };

    MarkAllNotificationRead = (event) => {
        this.props.onMarkAllNotificationRead().then((response) => {
            if (response.success === true) {
                this.GetUserDesktopNotification();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    NotificationMarkRead = (event, notification) => {
        this.props.onNotificationMarkRead(notification.system_user_log_id).then((response) => {
            if (response.success === true) {
                if (notification?.response_detail?.section_uuid && notification?.action_type.indexOf("bidsection_") !== -1) {
                    window.location = `/bid-response/${notification?.bid_detail?.bid_uuid}?activesection=${notification?.response_detail?.section_uuid}`;
                } else {
                    window.location = `/bid-response/${notification?.bid_detail?.bid_uuid}`;
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const { userdesktop_notifications } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="d-flex align-items-center ms-1 me-lg-2 notify-mob-outer" id="kt_header_user_menu_toggle">
                    <div className="dropdown">
                        <div className="drop-bell-change cursor-pointer symbol align-items-center symbol-30px symbol-md-40px " id="website_notifications" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-md-flex flex-column justify-content-center ms-2 ">
                                <span className="bell-btn-bg">
                                    <i className="far fa-bell">{userdesktop_notifications.length !== 0 && <span className="bullet bullet-dot bg-danger h-9px w-9px position-absolute translate-middle top-10px"></span>}</i>
                                </span>
                            </div>
                        </div>
                        <div className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fs-6 w-450px" aria-labelledby="website_notifications">
                            <div className="px-7 py-5 d-flex flex-stack border-bottom border-bottom-2 ">
                                <div className="fs-1 text-dark fw-bolder">Notifications</div>
                                {userdesktop_notifications.length !== 0 && (
                                    <div className="fs-6 text-main-color">
                                        <span className="fs-6 fw-bold-600 hover-new curser_pointer" onClick={(event) => this.MarkAllNotificationRead(event)}>
                                            Mark all as read
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className="p-5">
                                {userdesktop_notifications &&
                                    userdesktop_notifications.map((notification) => (
                                        <div
                                            className="d-flex mb-4 pb-4 border-bottom border-bottom-2 curser_pointer notify-outer"
                                            key={"notification_" + notification.system_user_log_id}
                                            onClick={(event) => this.NotificationMarkRead(event, notification)}
                                        >
                                            <div className="symbol-group symbol-hover">
                                                <div className="symbol symbol-25px symbol-circle me-3">
                                                    {notification?.action_user && notification?.action_user.avatar ? (
                                                        <span className="symbol_img symbol-labely">
                                                            <img
                                                                src={notification?.action_user?.avatar}
                                                                alt={notification?.action_user?.first_name + " " + notification?.action_user?.last_name}
                                                                title={notification?.action_user?.first_name + " " + notification?.action_user?.last_name}
                                                            />
                                                        </span>
                                                    ) : (
                                                        <span className="symbol-label bg-primary text-inverse-primary">{notification?.action_user?.first_name?.slice(0, 1) + notification?.action_user?.last_name?.slice(0, 1)}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="justify-content-between">
                                                    <span className="color1 fs-14 notify-txt me-1">{notification?.action_user?.first_name + " " + notification?.action_user?.last_name}</span>
                                                    <span className="color1 fs-14 notify-txt notify-txt-2">{renderHTML(notification?.desktopnotification_html)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                <div className="d-flex">
                                    <div className="d-flex flex-column flex-row-fluid">
                                        <div className="align-items-center text-center flex-wrap mb-1">
                                            <span className="fs-6 fw-bold-600 hover-new curser_pointer" onClick={(event) => this.props.HeaderNotificationsSidebarAction(event, true)}>
                                                View all notifications
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetUserDesktopNotification: userActions.GetUserDesktopNotification,
    onMarkAllNotificationRead: userActions.MarkAllNotificationRead,
    onNotificationMarkRead: userActions.NotificationMarkRead,
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderNotifications);