import React, { useState, useEffect, useCallback, useRef } from "react";

import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";
import { render } from "@testing-library/react";

import IndividualUsersListItems from "./IndividualUsersListItems";

import ConfirmDialog from "../../../components/Utility/ConfirmDialog";

import Loader from "../../../components/Utility/Loader";
import LoginSidebar from "../../../components/SuperAdminSidebar";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginFooter from "../../../components/SuperAdminFooter";
import * as SuperAdminUserActions from "../../../actions/SuperAdmin/index";

const IndividualUsersList = (props) => {
    const dispatch = useDispatch();
    const ConfirmDialogEvent = useRef(null);

    const [loader, setloader] = useState(false);
    const [user_lists, setuser_lists] = useState([]);
    const [usercount, setusercount] = useState(0);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("user_id");
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersearch, setfiltersearch] = useState(null);

    const GetAllUsers = useCallback(() => {
        sessionStorage.setItem("company_user_page", `/superadmin/individual-user-lists`);
        dispatch(SuperAdminUserActions.GetAllUsers(filtersearch, filterpage, filterlimit, filtersort, filterorder)).then((response) => {
            if (response.success === true) {
                setuser_lists(response.data);
                setusercount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    useEffect(() => {
        GetAllUsers();
    }, [GetAllUsers, filterlimit, filterpage, filtersort, filterorder, filtersearch]);

    const ConfirmChangeUserStatus = (user_detail, user_status) => {
        render(
            <ConfirmDialog
                title="User Status Change ?"
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                body="Are you sure you want to change user status?"
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                onConfirm={(event) => ChangeUserStatus(user_detail, user_status)}
            />
        );
    };

    const ChangeUserStatus = (user_detail, user_status) => {
        const form_data = new FormData();
        form_data.append("user_uud", user_detail.user_uud);
        form_data.append("user_status", user_status);

        setloader(true);
        dispatch(SuperAdminUserActions.ChangeUserStatus(form_data)).then((response) => {
            setloader(false);
            if (response.success === true) {
                GetAllUsers();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const ConfirmArchiveUser = (user_detail) => {
        render(
            <ConfirmDialog
                title="Archive User?"
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                body="Are you sure you want to archive this user?"
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                onConfirm={(event) => DeleteUser(user_detail)}
            />
        );
    };

    const DeleteUser = (user_detail) => {
        setloader(true);
        dispatch(SuperAdminUserActions.DeleteUser(user_detail.user_id)).then((response) => {
            setloader(false);
            if (response.success === true) {
                GetAllUsers();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const GetIndividualUsersExcel = () => {
        setloader(true);
        dispatch(SuperAdminUserActions.GetIndividualUsersExcel()).then((response) => {
            setloader(false);
            if (response.success === true) {
                const link = document.createElement("a");
                link.href = response.data;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="Users" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-4 mb-xl-8 bg-transparent">
                                                <div className="card-header border-0 p-0 align-items-center">
                                                    <h3 className="card-title align-items-start flex-column m-0">
                                                        <span className="card-label fw-bolder fs-22px mb-2">Individuals</span>
                                                    </h3>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                    <IndividualUsersListItems
                                        user_lists={user_lists}
                                        usercount={usercount}
                                        filterlimit={filterlimit}
                                        filterpage={filterpage}
                                        filtersort={filtersort}
                                        filterorder={filterorder}
                                        propsfiltersort={(field) => setfiltersort(field)}
                                        propsfilterorder={(order) => setfilterorder(order)}
                                        propsfilterpage={(event) => setfilterpage(event)}
                                        propsfiltersearch={(event) => setfiltersearch(event)}
                                        GetIndividualUsersExcel={() => GetIndividualUsersExcel()}
                                        ConfirmArchiveUser={(user_detail) => ConfirmArchiveUser(user_detail)}
                                        ConfirmChangeUserStatus={(user_detail, user_status) => ConfirmChangeUserStatus(user_detail, user_status)}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
function mapStateToProps(state) {	
    return {
        error: state.superdmin_users.error,
        loader: state.superdmin_users.loader,
        isAuthenticated: state.superdmin_users.isAuthenticated,
    };
}
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(IndividualUsersList);