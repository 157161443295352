import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import NumberFormat from "react-number-format";

import * as userActions from "../../actions/index";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
class PastProposalList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pricing_total: [],
            pricing_total_gst_amount: [],
            pricing_total_withoutGST: [],
            pricing_gst_percentagerate: 10,

            past_proposals: [],
        };
    }
    async componentDidMount() {
        await this.props.onGetLibraryPastProposalsByBid(this.props.bid_id).then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        past_proposals: response.data,
                    },
                    function () {
                        this.CalculatePricing();
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    CalculatePricing = () => {
        let past_proposals = this.state.past_proposals;
        if (past_proposals.responses.length > 0) {
            let response_content = past_proposals.responses;

            for (let bid_content_key in response_content) {
                let response = response_content[bid_content_key];

                let pricing_total = 0;
                // let pricing_total_gst_amount = 0;
                let pricing_total_withoutGST = 0;

                if (response.is_pricing) {
                    let pricing_response = response_content[bid_content_key];
                    let response_pricings = response_content[bid_content_key]["response_pricings"];

                    for (let pricing_key in response_pricings) {
                        let response_pricing = response_pricings[pricing_key];
                        if (response_pricing.pricing_unit && response_pricing.pricing_qty) {
                            let pricing_amount = parseFloat(response_pricing.pricing_unit) * response_pricing.pricing_qty;
                            pricing_total += parseFloat(pricing_amount);
                            response_pricings[pricing_key].pricing_amount = pricing_amount.toFixed(2);
                        } else {
                            response_pricings[pricing_key].pricing_amount = "";
                        }
                    }

                    pricing_total_withoutGST = pricing_total;
                    response_content[bid_content_key]["response_pricings"] = response_pricings;

                    // CalculatePricing Gst Start
                    let pricing_gst = pricing_response.pricing_gst;
                    if (pricing_gst === "true") {
                        let pricing_gst_percentagerate = this.state.pricing_gst_percentagerate;
                        let pricinggst_amount = (parseFloat(pricing_total) * parseFloat(pricing_gst_percentagerate)) / 100;

                        // pricing_total_gst_amount = pricinggst_amount;
                        pricing_total = parseFloat(pricing_total) + parseFloat(pricinggst_amount);
                    }
                    // CalculatePricing Gst End

                    let all_pricing_total = this.state.pricing_total || []
                    all_pricing_total[bid_content_key] = pricing_total.toFixed(2)

                    let all_pricing_total_withoutGST = this.state.pricing_total_withoutGST || []
                    all_pricing_total_withoutGST[bid_content_key] = pricing_total_withoutGST.toFixed(2)

                    let all_pricing_total_gst_amount = this.state.pricing_total_gst_amount || []
                    all_pricing_total_gst_amount[bid_content_key] = pricing_total.toFixed(2)

                    this.setState({
                        pricing_total: all_pricing_total,
                        response_content: response_content,
                        pricing_total_gst_amount: all_pricing_total_gst_amount,
                        pricing_total_withoutGST: all_pricing_total_withoutGST,
                    });
                }
            }
        }
    };

    render() {
        const { past_proposals } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header">
                            <LoginHeader page_title="Past Proposal" />
                            <div className="content d-flex flex-column flex-column-fluid">
                                <div className="post d-flex flex-column-fluid">
                                    <div className="container-xxl">
                                        <div className="l-content">
                                            <div className="container-xxl">
                                                <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap library-menu">
                                                        <li className="nav-item">
                                                            <Link to="/library" className="nav-link text-active-primary me-6">
                                                                Library
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <span className="nav-link text-active-primary me-6 ">{past_proposals ? past_proposals.bid_title : ""}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="row prposal-main-new">
                                                    <div className="mb-5 mb-xl-8">
                                                        <div className="border-0 pt-5 mb-2 d-block">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                        <h3 className="card-title align-items-start flex-column">
                                                                            <span className="card-label fw-bolder fs-2x mb-1">{past_proposals ? past_proposals.bid_title : ""}</span>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="pt-0">
                                                            <div className="row mb-4">
                                                                <div className="col-lg-12 p-0">
                                                                    {past_proposals?.responses?.length > 0
                                                                        ? past_proposals.responses.map((past_proposal, bid_content_key) => (
                                                                            <div className="response_content_section" key={"past_proposal_" + past_proposal.section_id}>
                                                                                <div className="response_content">
                                                                                    <div className="card mb-3">
                                                                                        <div className="card-header card-header-edit border-0 p-0 mb-0">
                                                                                            <h3 className="card-title align-items-start flex-column">
                                                                                                <span className="card-label fw-bold-700 fs-2mx mb-1 text-dark">{past_proposal.title}</span>
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div className="card-body p-0">
                                                                                            <div className="row mt-4">{renderHTML(past_proposal.description)}</div>
                                                                                            {past_proposal.response_pricings && past_proposal.response_pricings.length > 0 && (
                                                                                                <>
                                                                                                    <div className="row mt-4">
                                                                                                        <div className="table-responsive">
                                                                                                            <table className="table table-row-gray-300 align-middle custom-table-style">
                                                                                                                <thead>
                                                                                                                    <tr className="price-row-bg color1">
                                                                                                                        <th scope="col">No.</th>
                                                                                                                        <th scope="col">Item</th>
                                                                                                                        <th scope="col">Rate/Unit ($)</th>
                                                                                                                        <th scope="col">Qty</th>
                                                                                                                        <th scope="col">Amount</th>
                                                                                                                        <th scope="col"></th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {past_proposal.response_pricings.map((pricing_detail, pricing_index) => (
                                                                                                                        <tr key={"pricing_item_" + pricing_index}>
                                                                                                                            <td>{parseInt(pricing_index) + 1}</td>
                                                                                                                            <td>{pricing_detail?.pricing_item}</td>
                                                                                                                            <td>{pricing_detail?.pricing_unit}</td>
                                                                                                                            <td>{pricing_detail?.pricing_qty}</td>
                                                                                                                            <td>${pricing_detail?.pricing_amount}</td>
                                                                                                                        </tr>
                                                                                                                    ))}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row mt-4">
                                                                                                        <div className="col-sm-12">
                                                                                                            <div className="align-items-center ">
                                                                                                                <div className="justify-content-end d-flex align-items-center mt-3">
                                                                                                                    <h4 className="fs-14 mb-0 me-8">GST </h4>
                                                                                                                    <span className="color1 fw-bold-700 total-price-gst">
                                                                                                                        {past_proposal.pricing_gst === "true" ? this.state.pricing_gst_percentagerate + "%" : ""}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                {past_proposal.pricing_gst === "true" && (
                                                                                                                    <div className="total-price-gst">
                                                                                                                        <div className="justify-content-end d-flex align-items-center mt-3">
                                                                                                                            <h4 className="fs-14 mb-0 ">Sub Total </h4>
                                                                                                                            <span className="color1 total-price-gst">
                                                                                                                                <NumberFormat
                                                                                                                                    prefix="$"
                                                                                                                                    displayType="text"
                                                                                                                                    thousandSeparator={true}
                                                                                                                                    value={this.state.pricing_total_withoutGST[bid_content_key]}
                                                                                                                                />
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div className="justify-content-end d-flex align-items-center mt-3">
                                                                                                                            <h4 className="fs-14 mb-0 ">GST </h4>
                                                                                                                            <span className="color1 total-price-gst">
                                                                                                                                <NumberFormat
                                                                                                                                    prefix="$"
                                                                                                                                    displayType="text"
                                                                                                                                    thousandSeparator={true}
                                                                                                                                    value={this.state.pricing_total_gst_amount[bid_content_key] - this.state.pricing_total_withoutGST[bid_content_key]}
                                                                                                                                />
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                                <div className="justify-content-end d-flex align-items-center mt-3">
                                                                                                                    <h4 className="fs-14 mb-0 me-8">Total </h4>
                                                                                                                    <span className="color1 fw-bold-700 total-price-gst">${this.state.pricing_total[bid_content_key]}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                        : "No records found"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetLibraryPastProposalsByBid: userActions.GetLibraryPastProposalsByBid,
};
export default connect(mapStateToProps, mapDispatchToProps)(PastProposalList);